import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDate = (date: string) => {
  return dayjs(date).format('DD.MM.YYYY HH:mm');
};

export const convertToTimestamp = (dateString: string): number => {
  return new Date(dateString).getTime();
};

export function formatDateForInput(dateString: string) {
  if (!dateString) return '';
  const date = dayjs.utc(dateString);
  const year = date.year();
  const month = String(date.month() + 1).padStart(2, '0');
  const day = String(date.date()).padStart(2, '0');
  const hours = String(date.hour()).padStart(2, '0');
  const minutes = String(date.minute()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
