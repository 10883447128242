import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { ButtonSM } from 'components/button/Small';
import Card from 'components/card';
import { useMemo, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import useSWR from 'swr';
import { useDebounceValue } from 'usehooks-ts';
import { popUpService } from 'views/services/pupUpService';
import { TBundle, TPopUpCreatingType } from 'views/types/popup';
import { DeleteButton } from 'components/deleteButton';
import { Button } from 'components/button';
import { PopUpDialog } from './components/PopUpDialog';

const PopUpManagmentView = () => {
  const [creatingState, setCreatingState] = useState<TPopUpCreatingType>(null);
  const [popUpToEdit, setPopUpToEdit] = useState<TBundle | null>(null);

  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(`/api/pop-up`, () => popUpService.getCommonPopUps(), {
    refreshInterval: null,
  });

  const handleClosePopUp = () => {
    setCreatingState(null);
    setPopUpToEdit(null);
  };

  const handleClickCreatePopUp = () => {
    setCreatingState('new');
  };

  const handleEditPopUp = (pupUp: TBundle) => {
    setCreatingState('edit');
    setPopUpToEdit(pupUp);
  };

  const handleDeletePopUp = async (id: string) => {
    await popUpService.deletePopUp(id);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('shopQuest.title', {
        id: 'name',
        header: () => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Pop-up name
          </p>
        ),
        cell: info => (
          <div className="flex items-center gap-2">
            <p className="text-md font-medium text-navy-700 dark:text-white">
              {info.getValue()}
            </p>
          </div>
        ),
      }),
      columnHelper.accessor('shopQuest.type', {
        id: 'username',
        header: () => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Bundle type
          </p>
        ),
        cell: info => (
          <p className="text-md flex items-center gap-2 font-medium underline dark:text-white">
            {info.getValue()}
          </p>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: () => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Actions
          </p>
        ),
        cell: info => (
          <div className="flex gap-2 font-bold">
            <ButtonSM
              variant="primary"
              onClick={() => handleEditPopUp(info.row.original)}
            >
              Edit
            </ButtonSM>
            <DeleteButton
              action={() => handleDeletePopUp(info.row.original.shopQuest.id)}
              onSuccess={mutate}
            />
          </div>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [data] = useDebounceValue(tableData ?? [], 100);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Card extra={'mt-4 w-full max-h-[63rem] overflow-auto px-6 pb-6 grow'}>
        <div className="relative flex flex-col items-center justify-between gap-2 pt-4 sm:flex-row sm:gap-2 w-full">
          <div className="flex w-full flex-col gap-2 sm:flex-row sm:items-center">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Pop-up
            </div>
            <Button onClick={handleClickCreatePopUp} className="ml-auto">
              Create Pop-up
            </Button>
          </div>
        </div>

        <div className="mt-8 grow overflow-x-auto xl:overflow-x-hidden">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={clsx(
                          'bg-lightPrimary  pb-2 pr-4 pt-4 text-start dark:bg-[#030a27]',
                          header.column.getCanSort() && 'cursor-pointer',
                          'first:rounded-l-lg last:rounded-r-lg first:pl-4'
                        )}
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="flex justify-center py-10">
                      <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading && (tableData?.length ?? 0) <= 0 && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="py-10 text-center font-bold uppercase">
                      No data
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !!tableData &&
                tableData?.length > 0 &&
                table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      className="[&_td]:even:bg-gray-50 [&_td]:even:dark:bg-navy-900"
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[150px] border-white/0 py-2 pr-4 first:pl-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Card>

      {!!creatingState && (
        <PopUpDialog
          onOpenChange={handleClosePopUp}
          type={creatingState}
          isOpen={!!creatingState}
          popUp={popUpToEdit}
          onSuccess={mutate}
        />
      )}
    </>
  );
};

const columnHelper = createColumnHelper<TBundle>();

export default PopUpManagmentView;
