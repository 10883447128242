import clsx from 'clsx';

type TDurovFaceRendererProps = {
  index: number;
  bgColor?: string;
  bgColor2?: string;
  img?: string;
};

export const DurovFaceRenderer = ({
  index,
  bgColor,
  bgColor2,
  img,
}: TDurovFaceRendererProps) => {
  return (
    <div
      className={clsx(
        'flex-1 w-[60px] h-[60px] rounded-[10px] flex items-center justify-center'
      )}
      style={{
        background: `linear-gradient(0deg, ${bgColor} 0%, ${bgColor2} 100%)`,
      }}
    >
      {bgColor ? (
        <div dangerouslySetInnerHTML={{ __html: img }} />
      ) : (
        <span className="font-medium text-[#57575740] text-[15px]">
          {index}
        </span>
      )}
    </div>
  );
};
