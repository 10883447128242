import { TSecretCombos } from 'views/types/analytics';
import { DurovFaceRenderer } from './DurovFaceRenderer';
import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Popover,
  PopoverTrigger,
} from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/portal';
import { List } from 'react-virtualized';

type Props = {
  puzzles: TSecretCombos[][];
};

export const PuzzlesList = ({ puzzles }: Props) => {
  const currentSequence = puzzles?.[0];
  const otherSequences = puzzles?.slice(1);

  function getFormattedDate(daysAgo: number): string {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() + daysAgo);

    const day = String(pastDate.getDate()).padStart(2, '0');
    const month = String(pastDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = pastDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const rowRenderer = ({
    index,
    key,
    style,
  }: {
    index: number;
    key: string;
    style: React.CSSProperties;
  }) => {
    const sequence = otherSequences[index];
    const isLast = index === otherSequences.length - 1;
    const isFirst = index === 0;

    return (
      <div
        key={key}
        style={style}
        className={`text-xs dark:text-white ${!isFirst ? 'py-1' : ''} ${isLast ? '' : 'border-b border-gray-300'}`}
      >
        <span className="pl-1">{getFormattedDate(index + 1)}:</span>
        <div className="flex gap-1">
          {sequence.map(item => (
            <DurovFaceRenderer
              index={item.secretOrder}
              bgColor={item.backgroundColor}
              bgColor2={item.backgroundColorGradient}
              img={item.imageSvg}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <Popover>
      <PopoverTrigger>
        <button className="flex cursor-pointer gap-1">
          {currentSequence?.map(item => (
            <DurovFaceRenderer
              index={item.secretOrder}
              bgColor={item.backgroundColor}
              bgColor2={item.backgroundColorGradient}
              img={item.imageSvg}
            />
          ))}
        </button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent className="overflow-y-auto max-h-[50svh] w-fit bg-white dark:bg-navy-900 p-4 relative shadow-sm rounded-lg">
          <PopoverArrow />
          <PopoverBody className="flex flex-col gap-2">
            <List
              rowCount={otherSequences.length}
              rowHeight={85}
              width={252}
              height={500}
              rowRenderer={rowRenderer}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
