import { createStandaloneToast } from '@chakra-ui/toast';
import { AxiosError } from 'axios';
import { $api } from './interceptor';
import { GiveawayFormScheme } from 'views/admin/giveaways/scheme/taksForm';
import { giveawayStore } from 'views/store/useGiveawayStore';

export type SetPagesCount = (count: number) => void;

export const { toast } = createStandaloneToast();

const GiveawayApi = () => {
  const { setState } = giveawayStore;
  const getGiveaways = async () => {
    const { data } = await $api.get('/api/giveaways/list');
    setState({ giveawayList: data });
  };

  const createGiveaway = async (payload: GiveawayFormScheme) => {
    const formattedPayload = {
      ...payload,
      rewardDate: payload?.rewardDate || undefined,
      watchVideoUrl: payload?.watchVideoUrl || undefined,
      rewardAmount: +payload.rewardAmount,
    };

    try {
      await $api.post('/api/giveaways', formattedPayload);
      toast({
        title: 'Giveaway successfully created.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
      await getGiveaways();
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Something went wrong.',
          description: error.response.data.message || 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Something went wrong.',
          description: 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const updateGiveaway = async (
    payload: GiveawayFormScheme,
    giveawayID: string
  ) => {
    const formattedPayload = {
      ...payload,
      rewardAmount: +payload.rewardAmount,
    };

    try {
      await $api.patch(`/api/giveaways/${giveawayID}`, formattedPayload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast({
        title: 'Giveaway successfully updated.',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
      await getGiveaways();
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Something went wrong.',
          description: 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Something went wrong.',
          description: 'Please check form data',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const deleteGiveaway = async (giveawayID: string) => {
    try {
      const response = await $api.delete(`api/giveaways/${giveawayID}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        toast({
          title: 'Giveaway successfully deleted.',
          status: 'success',
          isClosable: true,
          position: 'top-right',
        });

        getGiveaways();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Something went wrong.',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Something went wrong.',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const getResultsList = async (giveawayID: string) => {
    try {
      const response = await $api.get(
        `api/giveaways/admin-results/${giveawayID}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Something went wrong.',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Something went wrong.',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      throw error;
    }
  };

  const uploadAdminURL = async (image: FormData) => {
    try {
      const response = await $api.post<
        [
          {
            fileUrl: string;
            filename: string;
          },
        ]
      >(`api/files/admin/upload`, image, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data[0].fileUrl;
    } catch (error) {
      if (error instanceof AxiosError) {
        toast({
          title: 'Failed to upload image',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      } else if (error instanceof Error) {
        toast({
          title: 'Failed to upload image',
          status: 'error',
          isClosable: true,
          position: 'top-right',
        });
      }
      console.log('upload image error:', error);
      throw error;
    }
  };

  return {
    getGiveaways,
    createGiveaway,
    deleteGiveaway,
    uploadAdminURL,
    updateGiveaway,
    getResultsList,
  };
};

export const giveawayService = GiveawayApi();
