import { formatNumber } from 'utils/formatNumber';
import { TPuzzleAnaliticsData } from 'views/services/analitics';

type TWheelProps = {
  data: TPuzzleAnaliticsData;
};

export const PuzzleStats = ({ data }: TWheelProps) => {
  const formattedTodayDate = new Date().toLocaleDateString('en-GB', {
    day: 'numeric',
    month: '2-digit',
    year: 'numeric',
  });

  return (
    <>
      {data && (
        <div className="w-full flex flex-col gap-8 max-h-[80svh] overflow-y-auto  mt-4">
          <div>
            <h2 className="text-2xl font-semibold mb-2 text-3xl border-b border-gray-200 dark:!border-white/10 pb-2">
              Daily-Puzzle Analytics
            </h2>

            <div className="flex gap-2 flex-col w-max">
              <div className="flex gap-8">
                <h3 className="min-w-[100px] font-bold">{`Users Completed [${formattedTodayDate}]:`}</h3>
                <p className="ml-auto">
                  {formatNumber(data.totalUsersCompletedToday)}
                </p>
              </div>

              <div className="flex gap-8">
                <h3 className="min-w-[100px] font-bold">
                  Users Completed General:
                </h3>
                <p className="ml-auto">
                  {formatNumber(data.totalUsersCompletedGeneral)}
                </p>
              </div>

              <div className="flex gap-8">
                <h3 className="min-w-[100px] font-bold">{`Users Not Completed: [${formattedTodayDate}]:`}</h3>
                <p className="ml-auto">
                  {formatNumber(data.totalUsersNotCompletedToday)}
                </p>
              </div>

              <div className="flex gap-8">
                <h3 className="min-w-[100px] font-bold">
                  Users Not Completed General:
                </h3>
                <p className="ml-auto">
                  {formatNumber(data.totalUsersNotCompletedGeneral)}
                </p>
              </div>

              <div className="flex gap-8">
                <h3 className="min-w-[100px] font-bold">{`Users Who Bought Ticket [${formattedTodayDate}]:`}</h3>
                <p className="ml-auto">
                  {formatNumber(data.totalUsersSpentTicketToday)}
                </p>
              </div>

              <div className="flex gap-8">
                <h3 className="min-w-[100px] font-bold">
                  Users Who Bought Ticket General:
                </h3>
                <p className="ml-auto">
                  {formatNumber(data.totalUsersSpentTicketGeneral)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
