import Card from 'components/card';
import { useMemo, useState } from 'react';
import { ButtonSM } from '../../../../../components/button/Small';
import { TaskDialog } from '../TaskDialog';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Button } from 'components/button';
import Pagination from 'components/Pagination/Pagination';
import { useForm } from 'react-hook-form';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import useSWR from 'swr';
import { Paginated } from 'types/paginated';
import { useDebounceValue } from 'usehooks-ts';
import { tasksService } from 'views/services/tasksService';
import { Task, TaskStatus, TaskType } from 'views/types/tasks';
import { TaskOrderForm } from '../../scheme/taskOrderForm';
import { TaskDelete } from '../TaskDelete';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import dayjs from 'dayjs';

function TasksTable() {
  const taskOrderForm = useForm<TaskOrderForm>({
    values: {
      order: {},
    },
  });
  const { setAnalitic } = useAnaliticsStore();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [searchDebounce] = useDebounceValue(search, 1000);
  const [stopAutoRefresh, setStopAutRefresh] = useState(true);
  const [taskTypeFilter, setTaskTypeFilter] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(
    `/tasks?page=${page}&search=${searchDebounce}&limit=${itemsPerPage}&filterBy=type&filter.type=${taskTypeFilter || ''}`,
    () =>
      tasksService.getPaginatedTasks({
        page: page,
        pageSize: itemsPerPage,
        filterBy: 'type',
        filterType: taskTypeFilter,
        searchValue: searchDebounce,
      }),
    {
      revalidateOnFocus: !stopAutoRefresh,
      onSuccess(data: Paginated<Task>) {
        const taskOrder = data.data.reduce(
          (acc: Record<Task['id'], string>, task) => {
            acc[task.id] = task?.order?.toString();
            return acc;
          },
          {}
        );
        taskOrderForm.setValue('order', taskOrder);
      },
    }
  );

  const filteredTasksTypes = useMemo(() => {
    return Object.values(TaskType).filter(
      type => type !== TaskType.QUIZ && type !== TaskType.STORY
    );
  }, []);

  const totalPages = Math.ceil(
    (tableData?.meta?.totalItems ?? 0) / itemsPerPage
  );

  const handleItemsPerPageChange = (items: number) => {
    setItemsPerPage(items);
    setPage(0);
  };

  const handleTaskChange = async () => {
    await mutate();
  };

  const handleAnaliticsModal = (id: string | null, title: string) => {
    setAnalitic(EAnaliticDataTypeEnum.task, id, title);
  };

  const handleTaskTypeClick = (type: string | null) => {
    setTaskTypeFilter(type);
    setPage(0);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        id: 'title',
        enableSorting: false,
        header: () => (
          <p className="w-[200px] min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
            Title
          </p>
        ),
        cell: info => (
          <p className="w-[200px] min-w-[200px] truncate text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('category', {
        id: 'category',
        enableSorting: false,
        header: () => (
          <p className="w-[100px] text-sm font-bold text-gray-600 dark:text-white">
            Category
          </p>
        ),
        cell: info => (
          <p className="w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('rewardPointsAmount', {
        id: 'awardPoints',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Award (points)
          </p>
        ),
        cell: info => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('usersCompletedCount', {
        id: 'completedUsersCount',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Users Completed / Users Total
          </p>
        ),
        cell: info => {
          const usersCompletedCount = info.row.original.usersCompletedCount;
          const usersTotalCount = info.row.original.usersTotalCount;

          return (
            <p className="w-[150px] min-w-[150px] text-sm font-bold text-navy-700 dark:text-white">
              {usersCompletedCount} / {usersTotalCount}
            </p>
          );
        },
      }),

      columnHelper.accessor('targetLanguages', {
        id: 'languageGroup',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Language Group
          </p>
        ),
        cell: info => {
          const languages = info.cell.getValue() as string[] | null;
          const displayValue =
            languages && Array.isArray(languages) && languages.length > 0
              ? languages.join(', ')
              : 'N/A';

          return (
            <p className="w-[150px] min-w-[150px] text-sm font-bold text-navy-700 dark:text-white">
              {displayValue}
            </p>
          );
        },
      }),

      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Creation Date
          </p>
        ),
        cell: info => (
          <p className="w-[120px] text-sm font-bold text-navy-700 dark:text-white">
            {dayjs(info.cell.getValue()).format('DD.MM.YYYY')}
          </p>
        ),
      }),

      columnHelper.display({
        id: 'actions',
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Actions
          </p>
        ),
        cell: info => (
          <div className="flex gap-2 font-bold">
            <TaskDialog
              onOpenChange={val => setStopAutRefresh(val)}
              onSuccess={handleTaskChange}
              task={info.row.original}
            >
              <ButtonSM variant="primary">Edit</ButtonSM>
            </TaskDialog>
            <TaskDelete task={info.row.original} onSuccess={handleTaskChange} />
            <ButtonSM
              variant="primary"
              onClick={() =>
                handleAnaliticsModal(
                  info.row.original.id,
                  info.row.original.title
                )
              }
            >
              Analytics
            </ButtonSM>
          </div>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [data] = useDebounceValue(tableData?.data ?? [], 100);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <div className="mt-8 mb-[-1px] overflow-x-auto max-w-[calc(100vw_-_400px)] inline-flex gap-2 text-navy-700 dark:text-white mx-6 bg-gray-100 dark:!bg-[#030a27] rounded-[10px] rounded-b-none">
        <button
          className={clsx(
            'text-sm px-2 py-2 min-w-[50px]',
            taskTypeFilter === null &&
              'rounded-[10px] border-[1px] border-gray-200 dark:border-[#ffffff33] border-b-0 rounded-b-none relative z-10 bg-white dark:!bg-navy-800'
          )}
          onClick={() => handleTaskTypeClick(null)}
        >
          All
        </button>
        {filteredTasksTypes.map((type, index) => (
          <button
            className={clsx(
              'text-sm px-2 py-2',
              type === taskTypeFilter &&
                'rounded-[10px] border-[1px] border-gray-200 dark:border-[#ffffff33] border-b-0 rounded-b-none relative z-10 bg-white dark:!bg-navy-800'
            )}
            key={index}
            onClick={() => handleTaskTypeClick(type)}
          >
            {type}
          </button>
        ))}
      </div>
      <Card
        extra={
          'w-full max-h-[63rem] overflow-auto px-6 pb-6 grow min-h-[800px] md:min-h-[500px]'
        }
      >
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white w-full">
            Tasks Dashboard
          </div>
          <div className="flex gap-4 items-center w-full justify-end">
            <input
              type="text"
              placeholder="Search by task title"
              className="block min-h-7 w-full grow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit sm:max-w-96"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                setPage(0);
              }}
            />
            <TaskDialog
              onOpenChange={setStopAutRefresh}
              onSuccess={handleTaskChange}
              formType="task"
            >
              <Button>
                <MdAdd />
                Create task
              </Button>
            </TaskDialog>
          </div>
        </header>

        <div className="mt-8 overflow-x-scroll">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={clsx(
                          'bg-lightPrimary  pb-2 pr-4 pt-4 text-start dark:bg-[#030a27]',
                          header.column.getCanSort() && 'cursor-pointer',
                          'first:rounded-l-lg last:rounded-r-lg first:pl-4'
                        )}
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="flex justify-center py-10">
                      <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading && (tableData?.data?.length ?? 0) <= 0 && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="py-10 text-center font-bold uppercase">
                      No data
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !!tableData &&
                tableData?.data?.length > 0 &&
                table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      className={clsx(
                        '[&_td]:even:bg-gray-50 [&_td]:even:dark:bg-navy-900',
                        row.original.status === TaskStatus.INACTIVE &&
                          'opacity-50'
                      )}
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[50px] border-white/0 py-2 pr-4 first:pl-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          totalItems={tableData?.meta?.totalItems}
          onPageChange={setPage}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </Card>
    </>
  );
}

export default TasksTable;
const columnHelper = createColumnHelper<Task>();
