import clsx from 'clsx';
import ticketImg from '../../assets/img/shop/golden-ticket.png';

type TProps = {
  width?: number;
  height?: number;
  className?: string;
};

export const GoldTicketIcon = ({
  width = 16,
  height = 16,
  className,
}: TProps) => {
  return (
    <img
      src={ticketImg}
      alt="ticket"
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      className={clsx(className && className)}
    />
  );
};
