import { formatNumber } from 'utils/formatNumber';
import { TWheelAnaliticsData } from 'views/services/analitics';

type TWheelProps = {
  data: TWheelAnaliticsData;
};

export const WheelStats = ({ data }: TWheelProps) => {
  return (
    <>
      {data && (
        <div className="w-full flex flex-col gap-8 max-h-[80svh] overflow-y-auto  mt-4">
          <div>
            <h2 className="text-2xl font-semibold mb-2 text-3xl border-b border-gray-200 dark:!border-white/10 pb-2">
              Spin Wheel Analytics
            </h2>
            <h3 className="text-2xl font-semibold mb-8 text-lg">
              Users Analytics:
            </h3>

            <div className="flex gap-1 flex-col w-max">
              <div className="flex gap-4">
                <h3 className="min-w-[100px] font-bold">Users Total:</h3>
                <p className="ml-auto">{formatNumber(data.usersTotalCount)}</p>
              </div>

              <div className="flex gap-4">
                <h3 className="min-w-[100px] font-bold">Games Count:</h3>
                <p className="ml-auto">{formatNumber(data.gamesTotalCount)}</p>
              </div>

              <div className="flex gap-4">
                <h3 className="min-w-[100px] font-bold">AVG Games for User:</h3>
                <p className="ml-auto">{formatNumber(data.avgGamesPerUser)}</p>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-200 dark:!border-white/10 -mt-4" />

          <div>
            <h3 className="text-2xl font-semibold mb-8 text-lg">
              Rewards Analytics:
            </h3>

            {Object.entries(data.rewardAnalytics).map(([key, value]) => (
              <div className="flex gap-20 w-full mb-4" key={key}>
                <h3 className="min-w-[120px] max-w-[120px] font-bold">
                  {key}:
                </h3>

                <div className="grid gap-4 grid-cols-3">
                  {value.map((item, index) => (
                    <div className="w-[170px]" key={index}>
                      <h4 className="font-bold">{`${item.amount} ${key}`}</h4>
                      <p>Count: {formatNumber(item.statsCount)}</p>
                      <p>
                        {' '}
                        {key === 'USDT' && '$ '}
                        {key === 'TICKETS' && 'Tickets '}
                        {key === 'STARS' && 'Stars '}
                        Amount: {formatNumber(item.statsAmount)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
