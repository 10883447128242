import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { EBundleVariant, TBundle, TPopUpCreatingType } from 'views/types/popup';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import { Bundles } from './Bundles';
import Select from 'components/fields/Select';
import { PopUpImage } from './PopUpImage';
import { SaveButton } from './SaveButton';
import { popUpService } from 'views/services/pupUpService';
import Switch from 'components/switch';

interface UserDialogProps {
  isOpen: boolean;
  type: TPopUpCreatingType;
  popUp?: TBundle;
  children?: React.ReactNode;
  onOpenChange: () => void;
  onSuccess: () => void;
}

export function PopUpDialog({
  isOpen,
  popUp,
  onOpenChange,
  children,
  type,
  onSuccess,
}: UserDialogProps) {
  const [isPending, setIsPending] = useState(false);

  const incomeBundleSet = popUp?.bundles[0];
  const incomeShopQuest = popUp?.shopQuest;

  const form = useForm<TBundle>({
    values: {
      bundles: [
        {
          giveawayTicketsCount: incomeBundleSet?.giveawayTicketsCount || 0,
          pointsCount: incomeBundleSet?.pointsCount || 0,
          starsPrice: incomeBundleSet?.starsPrice || 0,
          ticketsCount: incomeBundleSet?.ticketsCount || 0,
        },
      ],
      shopQuest: {
        description: incomeShopQuest?.description || '',
        id: incomeShopQuest?.id || '',
        imageUrl: incomeShopQuest?.imageUrl || '',
        isActive: incomeShopQuest ? incomeShopQuest.isActive : true,
        title: incomeShopQuest?.title || '',
        type: incomeShopQuest?.type || EBundleVariant.COMMON,
      },
    },
    mode: 'all',
  });

  const handleClose = () => {
    onOpenChange();
    form.reset();
  };

  const handleCreateBundle = async (data: TBundle) => {
    switch (data.shopQuest.type) {
      case EBundleVariant.COMMON:
        await popUpService.createCommonBundle(data);
        break;
      case EBundleVariant.PROGRESSIVE:
        // await popUpService.createCommonBundle(data);
        break;
      case EBundleVariant.DISCONT:
        // await popUpService.createCommonBundle(data);
        break;
      default:
        break;
    }
  };

  const handleUpdateBundle = async (data: TBundle) => {
    switch (data.shopQuest.type) {
      case EBundleVariant.COMMON:
        await popUpService.updateCommonBundle(data);
        break;
      case EBundleVariant.PROGRESSIVE:
        // await popUpService.updateCommonBundle(data);
        break;
      case EBundleVariant.DISCONT:
        // await popUpService.updateCommonBundle(data);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (data: TBundle) => {
    try {
      setIsPending(true);

      if (type === 'new') {
        await handleCreateBundle(data);
      } else {
        await handleUpdateBundle(data);
      }

      onSuccess();
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <Dialog.Root modal open={isOpen} onOpenChange={handleClose}>
      <FormProvider {...form}>
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            aria-describedby="request-dialog-title"
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white"
          >
            <Dialog.Title
              id="request-dialog-title"
              className="text-mauve12 mb-10 text-2xl font-medium"
            >
              {type === 'new' ? 'Create new pop-up' : 'Edit pop-up'}
            </Dialog.Title>

            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <div className="max-h-[calc(85vh_-_227px)] min-h-[292px] grow overflow-x-auto flex flex-col gap-2">
                <fieldset className="flex gap-4 ml-1 mb-2">
                  <label
                    className="flex items-center gap-2 cursor-pointer"
                    htmlFor="isActive"
                  >
                    <Switch
                      id="isActive"
                      {...form.register('shopQuest.isActive')}
                    />
                    <span>Is active</span>
                  </label>
                </fieldset>

                <Select
                  id="shopQuest.type"
                  variant="auth"
                  label="Variant"
                  {...form.register('shopQuest.type')}
                  disabled={type === 'edit'}
                >
                  {Object.values(EBundleVariant).map(variant => (
                    <option
                      key={variant}
                      value={variant}
                      disabled={variant !== EBundleVariant.COMMON}
                    >
                      {variant}
                    </option>
                  ))}
                </Select>

                <fieldset>
                  <InputField
                    placeholder="Name"
                    extra=""
                    id="shopQuest.title"
                    variant="auth"
                    type="text"
                    label="Name"
                    {...form.register('shopQuest.title', {
                      required: 'This field is required',
                    })}
                    state={
                      form.formState.errors.shopQuest?.title ? 'error' : ''
                    }
                  />

                  {form.formState.errors.shopQuest?.title && (
                    <span className="text-red-500">
                      {form.formState.errors.shopQuest.title.message}
                    </span>
                  )}
                </fieldset>

                <PopUpImage />

                <Bundles />
              </div>

              <div className="mt-[10px] flex flex-col gap-2">
                <div className="flex gap-2">
                  <SaveButton isPending={isPending} />
                </div>
              </div>
            </form>

            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </FormProvider>
    </Dialog.Root>
  );
}
