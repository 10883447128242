import { Button } from 'components/button';
import { useFormContext } from 'react-hook-form';
import { TBundle } from 'views/types/popup';

type TProps = {
  isPending: boolean;
};

export const SaveButton = ({ isPending }: TProps) => {
  const form = useFormContext<TBundle>();

  const isEdited = form.formState.isDirty;

  return (
    <Button
      className="w-full justify-center"
      disabled={isPending || !isEdited}
      type="submit"
    >
      {isPending ? 'Saving...' : 'Save changes'}
    </Button>
  );
};
