import Card from 'components/card';
import { useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import useSWR from 'swr';
import { useDebounceValue } from 'usehooks-ts';
import { usersService } from 'views/services/usersService';
import { ESetReferralEvents, User } from 'views/types/typeUser';
import Pagination from '../../../../../components/Pagination/Pagination';
import InputField from 'components/fields/InputField';
import { getTelegramIdFromReferralLink } from 'utils/getTelegramIdFromReferralLink';
import Switch from 'components/switch';
import { MdArrowDropUp } from 'react-icons/md';

function UsersTable() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [searchField] = useState('username');
  const [page, setPage] = useState(0);
  const [searchDebounce] = useDebounceValue(search, 1000);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  let sortingQuery: string;
  const sortingValue = sorting?.[0];
  if (sortingValue) {
    switch (sortingValue.id) {
      case 'referralCount':
        sortingQuery = 'referral_count:';
        break;
      case 'points':
        sortingQuery = 'clicks_count:';
        break;
      case 'completedTasks':
        sortingQuery = 'done_tasks_amount:';
        break;
      case 'age':
        sortingQuery = 'age:';
        break;
      case 'completedCampaigns':
        sortingQuery = 'done_campaigns_amount:';
        break;
      case 'tgAccountCreationDate':
        sortingQuery = 'telegram_user_id:';
        break;
      case 'totalTonSpent':
        sortingQuery = 'total_ton_spent:';
        break;
      case 'totalTgStarsSpent':
        sortingQuery = 'total_tg_stars_spent:';
        break;
    }
    sortingQuery += sortingValue.desc ? 'DESC' : 'ASC';
  }

  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(
    `/users?page=${page}&searchField=${searchField}&search=${searchDebounce}&searchBy=telegram_user_id&limit=${itemsPerPage}&sortBy=${sortingQuery}`,
    () =>
      usersService.getPaginatedUsersList({
        page: page,
        pageSize: itemsPerPage,
        searchField: searchField,
        searchValue: getTelegramIdFromReferralLink(searchDebounce),
        sortingQuery: sortingQuery,
      }),
    {
      revalidateOnFocus: false,
    }
  );

  const handleCustomIncomePercent = async (value: number, userId: string) => {
    if (!isNaN(value)) {
      await usersService.upgradeUserIncomePercent(userId, value);
    }
  };

  const handleChangeSetReferralEvent = async (
    value: boolean,
    userId: string
  ) => {
    await usersService.upgradeUserSetReferralEvent(
      userId,
      value
        ? ESetReferralEvents.CLAIM_DAILY_REWARD
        : ESetReferralEvents.LAUNCH_APP
    );
    mutate();
  };

  const generateSortingMarker = (columnId: string) => {
    const ascSorting = sorting?.[0]?.desc === false;
    const sortingId = sorting?.[0]?.id;
    return (
      sortingId === columnId && (
        <span>
          {ascSorting ? (
            <MdArrowDropUp className="ml-2 inline-block rotate-180 scale-[1.8] fill-gray-800 dark:fill-white" />
          ) : (
            <MdArrowDropUp className="ml-2 inline-block scale-[1.8] fill-gray-800 dark:fill-white" />
          )}
        </span>
      )
    );
  };

  const columns = [
    columnHelper.accessor('telegramUserId', {
      id: 'telegramUserId',
      header: () => (
        <p className="w-[150px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
          Unique TelegramId
          {generateSortingMarker('telegramUserId')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('telegramName', {
      id: 'telegramName',
      header: () => (
        <p className="w-[200px] min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
          TG Name
          {generateSortingMarker('telegramName')}
        </p>
      ),
      cell: info => (
        <div className="flex w-[200px] min-w-[200px] items-center">
          <a
            href={`http://t.me/${info.row.original.telegramName}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-bold text-navy-700 underline dark:text-white max-w-[200p] truncate"
            title={info.cell.getValue()}
          >
            {info.cell.getValue()}
          </a>
        </div>
      ),
    }),
    columnHelper.accessor('username', {
      id: 'username',
      header: () => (
        <p className=" w-[200px] min-w-max text-sm font-bold text-gray-600 dark:text-white">
          TG Username
          {generateSortingMarker('username')}
        </p>
      ),
      cell: info => (
        <div className="flex min-w-[200px] items-center w-fit">
          <a
            href={`http://t.me/${info.row.original.username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm font-bold text-navy-700 underline dark:text-white w-fit"
          >
            {info.cell.getValue()}
          </a>
        </div>
      ),
    }),
    columnHelper.accessor('last_device', {
      id: 'last_device',
      header: () => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
          System
          {generateSortingMarker('last_device')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">No Data</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('customIncomePercent', {
      id: 'customIncomePercent',
      header: () => (
        <p className="w-fit min-w-[180px] text-sm font-bold text-gray-600 dark:text-white">
          Custom Passive Income %
        </p>
      ),
      cell: info => (
        <p className="w-fit pr-2 min-w-[180px] text-sm font-bold text-navy-700 dark:text-white">
          <InputField
            variant="auth"
            defaultValue={
              tableData.data.find(user => user.id == info.cell.row.original.id)
                ?.customIncomePercent || ''
            }
            className="mt-0 h-8 pl-1 rounded-md dark:text-[#000]"
            extra="w-full !border border-gray-300 rounded-md"
            placeholder="Enter custom %"
            id="customIncomePercent"
            type="number"
            onChange={e => {
              handleCustomIncomePercent(
                +e.target.value,
                info.cell.row.original.id
              );
            }}
          />
        </p>
      ),
    }),
    columnHelper.accessor('wallet.address', {
      id: 'wallet.address',
      header: () => (
        <p className="w-fit min-w-[120px] text-sm font-bold text-gray-600 dark:text-white">
          Connected Wallet
          {generateSortingMarker('wallet.address')}
        </p>
      ),
      cell: info => (
        <p className="w-fit pr-2 min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Not Connected</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('totalTonSpent', {
      id: 'totalTonSpent',
      header: () => (
        <p className="w-[130px] min-w-[130px] text-sm font-bold text-gray-600 dark:text-white">
          TON Donates
          {generateSortingMarker('totalTonSpent')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()?.toFixed(2)}
        </p>
      ),
    }),
    columnHelper.accessor('totalTgStarsSpent', {
      id: 'totalTgStarsSpent',
      header: () => (
        <p className="w-[140px] min-w-[140px] text-sm font-bold text-gray-600 dark:text-white">
          TG Stars Donates
          {generateSortingMarker('totalTgStarsSpent')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('points', {
      id: 'points',
      header: () => (
        <p className="w-[150px] min-w-[150px] text-sm font-bold text-gray-600 dark:text-white">
          Earned Points
          {generateSortingMarker('points')}
        </p>
      ),
      cell: info => (
        <p className="w-[150px] min-w-[150px] text-sm font-bold text-navy-700 dark:text-white">
          {Math.floor(info.cell.getValue()) ?? 0}
        </p>
      ),
    }),
    columnHelper.accessor('country.name', {
      id: 'lastUserCountry',
      header: () => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
          Country
          {generateSortingMarker('lastUserCountry')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">No Data</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('selectedCountry.name', {
      id: 'selectedCountry.name',
      header: () => (
        <p className="w-[180px] min-w-[120px] text-sm font-bold text-gray-600 dark:text-white">
          Country from Bio
          {generateSortingMarker('selectedCountry.name')}
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Not Selected</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('userLanguage', {
      id: 'userLanguage',
      header: () => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
          Language
          {generateSortingMarker('userLanguage')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('isPremium', {
      id: 'isPremium',
      header: () => (
        <p className="w-[150px] min-w-[150px] text-sm font-bold text-gray-600 dark:text-white">
          TG Premium check
          {generateSortingMarker('isPremium')}
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() ? (
            <span>Yes</span>
          ) : (
            <span className="text-gray-500">No</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('referralCount', {
      id: 'referralCount',
      header: () => (
        <p className="w-[180px] min-w-[180px] text-sm font-bold text-gray-600 dark:text-white">
          Number of invited users
          {generateSortingMarker('referralCount')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('completedTasks', {
      id: 'completedTasks',
      header: () => (
        <p className="w-[190px] min-w-[190px] text-sm font-bold text-gray-600 dark:text-white">
          Count of completed Tasks
          {generateSortingMarker('completedTasks')}
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('setReferralEvent', {
      id: 'setReferralEvent',
      header: () => (
        <p className="w-fit min-w-[180px] text-sm font-bold text-gray-600 dark:text-white">
          Complicated Ref
          {generateSortingMarker('setReferralEvent')}
        </p>
      ),
      cell: info => (
        <p className="w-fit pr-2 min-w-[180px] text-sm font-bold text-navy-700 dark:text-white">
          {
            <Switch
              onChange={e =>
                handleChangeSetReferralEvent(
                  e.target.checked,
                  info.cell.row.original.id
                )
              }
              checked={
                info.cell.getValue() === ESetReferralEvents.CLAIM_DAILY_REWARD
              }
            />
          }
        </p>
      ),
    }),
    columnHelper.accessor('age', {
      id: 'age',
      header: () => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-gray-600 dark:text-white">
          Age
          {generateSortingMarker('age')}
        </p>
      ),
      cell: info => (
        <p className="w-[120px] min-w-[120px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Unknown</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('gender', {
      id: 'gender',
      header: () => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
          Sex
          {generateSortingMarker('gender')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">No Data</span>
          )}
        </p>
      ),
    }),
    columnHelper.accessor('utmSource', {
      id: 'utmSource',
      header: () => (
        <p className="w-[160px] min-w-[160px] text-sm font-bold text-gray-600 dark:text-white">
          Traffic Source
          {generateSortingMarker('utmSource')}
        </p>
      ),
      cell: info => (
        <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
          {info.cell.getValue() || (
            <span className="text-gray-500">Another Traffic Source</span>
          )}
        </p>
      ),
    }),
  ];

  const [data] = useDebounceValue(tableData?.data ?? [], 100);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const totalPages = Math.ceil(
    (tableData?.meta.totalItems ?? 0) / itemsPerPage
  );

  const handleItemsPerPageChange = (items: number) => {
    setItemsPerPage(items);
    setPage(0);
  };

  return (
    <Card extra={'w-full max-h-[63rem] overflow-auto px-6 pb-6 grow'}>
      <header className="relative flex items-center gap-4 pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Users
        </div>

        <input
          type="text"
          placeholder={`Search by TG name/TG username/TG id/referral link`}
          className="block ml-auto min-h-7 w-full grow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit max-w-[500px]"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            setPage(0);
          }}
        />
      </header>

      <div className="mt-8 grow overflow-x-auto">
        <table className="w-full">
          <thead className="sticky top-0 z-10">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                    className={clsx(
                      'bg-lightPrimary  pb-2 pr-4 pt-4 text-start dark:bg-[#030a27]',
                      header.column.getCanSort() && 'cursor-pointer',
                      'first:rounded-l-lg last:rounded-r-lg first:pl-4'
                    )}
                  >
                    <div className="items-center justify-between text-xs text-gray-200">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="flex justify-center py-10">
                    <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                  </p>
                </td>
              </tr>
            )}
            {!isLoading && (tableData?.data?.length ?? 0) <= 0 && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="py-10 text-center font-bold uppercase">
                    No data
                  </p>
                </td>
              </tr>
            )}
            {!isLoading &&
              !!tableData &&
              tableData?.data?.length > 0 &&
              table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  className="[&_td]:even:bg-gray-50 [&_td]:even:dark:bg-navy-900"
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      className="min-w-[100px] border-white/0 py-2 pr-4 first:pl-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        totalItems={tableData?.meta.totalItems}
        onPageChange={setPage}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </Card>
  );
}

export default UsersTable;

const columnHelper = createColumnHelper<User>();
