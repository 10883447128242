import { useFormContext } from 'react-hook-form';
import InputField from 'components/fields/InputField';
import TextArea from 'components/fields/TextArea';
import { GiveawayFormScheme } from '../../scheme/taksForm';
import { useState } from 'react';
import { Button } from 'components/button';
import { MdAdd } from 'react-icons/md';
import { Giveaway, GiveawayTask } from 'views/types/tasks';
import { GiveawayTaskItem } from '../GiveawayTask';
import { preventScrollOnNumberInput } from 'utils/input';
import { giveawayService } from 'views/services/giveawayService';

type TTaskFormProps = {
  isEdit: boolean;
  giveaway: Giveaway;
  setIsChangedImage: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTask: React.Dispatch<React.SetStateAction<GiveawayTask>>;
};

export const GiveawayForm = ({
  isEdit,
  giveaway,
  setIsChangedImage,
  setIsOpenTaskModal,
  setTask,
}: TTaskFormProps) => {
  const form = useFormContext<GiveawayFormScheme>();
  const [endDate, setEndDate] = useState<string>();
  const [rewardDate, setRewardDate] = useState<string>();

  const [isRewardImageLoading, setIsRewardImageLoading] = useState(false);

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const handleOpen = (isOpen: boolean, task?: GiveawayTask) => {
    setIsOpenTaskModal(isOpen);
    setIsChangedImage(true);
    if (task) {
      setTask(task);
    }
  };

  const handleChooseEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    e.target.blur();
  };

  const handleSetRewardDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRewardDate(e.target.value);
    e.target.blur();
  };

  const validateFutureDate = (value: string) => {
    const selectedDate = new Date(value);
    selectedDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (selectedDate <= today) {
      return 'End date must be a future date';
    }

    return true;
  };

  const validateFutureRewardDate = (value: string) => {
    const selectedDate = new Date(value);
    const endDate = new Date(form.getValues('endDate'));
    const diff = selectedDate.getTime() - endDate.getTime();
    const diffHours = diff / (1000 * 60 * 60);

    if (diffHours < 1) {
      return 'Reward date must be at least 1 hour bigger than end date';
    }

    return true;
  };

  const handleRewardImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    if (file.size > 3000000) {
      e.target.value = '';
      alert('File is too large, max size is 3mb');
      return;
    }

    if (
      !file.type.includes('image/jpeg') &&
      !file.type.includes('image/png') &&
      !file.type.includes('image/svg+xml')
    ) {
      e.target.value = '';
      alert('Invalid file type, only jpg, jpeg, png, svg are allowed');
      return;
    }

    const formData = new FormData();
    formData.append('files', file, file.name);
    setIsRewardImageLoading(true);
    giveawayService
      .uploadAdminURL(formData)
      .then(url => {
        form.setValue('rewardImageUrl', url, { shouldDirty: true });
        form.clearErrors('rewardImageUrl');
      })
      .catch(error => console.error('Error uploading icon:', error))
      .finally(() => setIsRewardImageLoading(false));
  };

  return (
    <>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.rewardAmount ? 'error' : undefined}
          extra="w-full mb-3"
          label="Rewards amount"
          placeholder="Enter rewards amount"
          id="rewardAmount"
          onWheel={preventScrollOnNumberInput}
          {...form.register('rewardAmount', {
            required: 'This field is required',
            pattern: {
              value: /^(?!0$)[0-9]+([.,]{1}[0-9]+)?$/g,
              message: 'Invalid number format',
            },
          })}
        />
        {form.formState.errors.rewardAmount && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.rewardAmount.message}
          </span>
        )}
      </fieldset>

      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.endDate ? 'error' : undefined}
          extra="w-full mb-3"
          label="End date"
          placeholder="Enter end date for this giveaway"
          id="endDate"
          type="date"
          value={endDate}
          onChange={handleChooseEndDate}
          onWheel={preventScrollOnNumberInput}
          {...form.register('endDate', {
            required: 'This field is required',
            validate: validateFutureDate,
          })}
        />
        {form.formState.errors.endDate && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.endDate.message}
          </span>
        )}
      </fieldset>

      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.rewardDate ? 'error' : undefined}
          extra="w-full mb-3"
          label="Reward date"
          placeholder="Enter reward date for this giveaway"
          id="rewardDate"
          type="datetime-local"
          value={rewardDate}
          onChange={handleSetRewardDate}
          onWheel={preventScrollOnNumberInput}
          {...form.register('rewardDate', {
            required: false,
            validate: validateFutureRewardDate,
          })}
        />
        {form.formState.errors.rewardDate && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.rewardDate.message}
          </span>
        )}
      </fieldset>

      <div className="flex flex-col">
        <fieldset className="mb-[15px] flex flex-col">
          <TextArea
            variant="auth"
            state={form.formState.errors.description ? 'error' : undefined}
            extra="w-full mb-3"
            label="Description"
            placeholder="Enter giveaway description"
            id="description"
            {...form.register('description', {
              required: 'This field is required',
            })}
          />
          {form.formState.errors.description && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.description.message}
            </span>
          )}
        </fieldset>

        <fieldset className="mb-[15px] flex flex-col">
          <InputField
            variant="auth"
            state={form.formState.errors.watchVideoUrl ? 'error' : undefined}
            extra="w-full mb-3"
            label="Watch video URL"
            placeholder="Enter watch video URL"
            id="watchVideoUrl"
            onWheel={preventScrollOnNumberInput}
            {...form.register('watchVideoUrl', {
              required: false,
            })}
          />
          {form.formState.errors.watchVideoUrl && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.watchVideoUrl.message}
            </span>
          )}
        </fieldset>

        <fieldset className="mb-[15px] flex flex-col">
          <TextArea
            variant="auth"
            state={form.formState.errors.rewardText ? 'error' : undefined}
            extra="w-full mb-3"
            label="Share story text"
            placeholder="Enter share story text"
            id="rewardText"
            {...form.register('rewardText', {
              required: 'This field is required',
            })}
          />
          {form.formState.errors.rewardText && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.rewardText.message}
            </span>
          )}
        </fieldset>

        <fieldset className="mb-[15px] flex flex-col">
          <div className="flex flex-col">
            <InputField
              variant="auth"
              onChange={handleRewardImageChange}
              type="file"
              accept=".jpg,.jpeg,.png,.svg"
              state={form.formState.errors.rewardImageUrl ? 'error' : undefined}
              extra="w-full mb-3"
              label="Reward image URL"
              placeholder="Enter reward image URL"
              id="rewardImageUrl"
            />
            {isRewardImageLoading && <span>Loading...</span>}
            {form.getValues('rewardImageUrl') && !isRewardImageLoading && (
              <img
                src={form.getValues('rewardImageUrl')}
                alt="Icon"
                className="my-2 w-12 h-12 object-cover rounded-lg"
              />
            )}
          </div>
        </fieldset>
      </div>

      {isEdit && (
        <div>
          <p>Tasks:</p>
          <div className="flex flex-col mb-2 mt-2 gap-2">
            {!!giveaway?.tasks &&
              giveaway.tasks.map(taskItem => (
                <GiveawayTaskItem
                  task={taskItem}
                  key={taskItem.id}
                  handleOpenTaskModal={handleOpen}
                />
              ))}
          </div>

          <Button onClick={() => handleOpen(true)}>
            <MdAdd />
            Add task
          </Button>
        </div>
      )}
    </>
  );
};
