import InputField from 'components/fields/InputField';
import { GoldTicketIcon } from 'components/icons/GoldTicketIcon';
import { StarIcon } from 'components/icons/StarIcon';
import { StarVioletIcon } from 'components/icons/StarVioletIcon';
import { TicketIcon } from 'components/icons/TicketIcon';
import { useFormContext, useWatch } from 'react-hook-form';
import { TBundle, TBundleSetItem } from 'views/types/popup';

const atLeast1Message = 'At least one field is required';

export const Bundles = () => {
  const form = useFormContext<TBundle>();
  const currentBundles: TBundleSetItem[] = useWatch({ name: 'bundles' });

  const intPattern = {
    value: /^[0-9]*$/,
    message: 'Only integer numbers',
  };

  const validatePattern = (
    value: string,
    isPoints: boolean,
    isTickets: boolean,
    isGoldTickets: boolean,
    index: number
  ) => {
    const bundle = currentBundles[index];

    const validate = (val: string, secondVal: number, thirdVal: number) => {
      const isOk = intPattern.value.test(val);
      const isOtherValueProvided = !!secondVal || !!thirdVal;

      if (isOk && val.trim() !== '') {
        return true;
      }

      if (!isOk) {
        return intPattern.message;
      }

      if (isOtherValueProvided) {
        return true;
      } else {
        return atLeast1Message;
      }
    };

    if (isPoints) {
      return validate(value, bundle.ticketsCount, bundle.giveawayTicketsCount);
    }

    if (isTickets) {
      return validate(value, bundle.pointsCount, bundle.giveawayTicketsCount);
    }

    if (isGoldTickets) {
      return validate(value, bundle.pointsCount, bundle.ticketsCount);
    }

    return true;
  };

  return (
    <div className="border border-gray-200 dark:border-white/10 p-4 mt-8 rounded-xl bg-gray-50 dark:bg-gray-900">
      <h4 className="w-fit mx-auto text-xl font-bold mb-4">Bundle sets</h4>
      {currentBundles.map((_bundle: TBundleSetItem, index: number) => (
        <div
          key={index}
          className="pt-6 flex flex-col items-center justify-center gap-4 border border-gray-200 dark:border-white/10 p-3 rounded-xl bg-white dark:bg-gray-800"
        >
          <div className="flex gap-4">
            <div className="flex flex-col items-center gap-2">
              <StarIcon width={60} height={40} />
              <InputField
                placeholder="Points count (STARS)"
                extra="w-[150px]"
                id={`bundle[${index}].pointsCount`}
                variant="auth"
                type="text"
                label="Points count (STARS)"
                {...form.register(`bundles.${index}.pointsCount`, {
                  validate: value =>
                    validatePattern(
                      value.toString(),
                      true,
                      false,
                      false,
                      index
                    ),
                })}
                state={
                  form.formState.errors.bundles?.[index]?.pointsCount
                    ? 'error'
                    : ''
                }
              />
              {form.formState.errors.bundles?.[index]?.pointsCount && (
                <span className="text-red-500 text-sm">
                  {form.formState.errors.bundles?.[index]?.pointsCount?.message}
                </span>
              )}
            </div>

            <div className="flex flex-col items-center gap-2">
              <TicketIcon width={60} height={40} />
              <InputField
                placeholder="Tickets count"
                extra="w-[150px]"
                id={`bundle[${index}].ticketsCount`}
                variant="auth"
                type="text"
                label="Tickets count"
                {...form.register(`bundles.${index}.ticketsCount`, {
                  validate: value => {
                    return validatePattern(
                      value.toString(),
                      false,
                      true,
                      false,
                      index
                    );
                  },
                })}
                state={
                  form.formState.errors.bundles?.[index]?.ticketsCount
                    ? 'error'
                    : ''
                }
              />
              {form.formState.errors.bundles?.[index]?.ticketsCount && (
                <span className="text-red-500 text-sm">
                  {
                    form.formState.errors.bundles?.[index]?.ticketsCount
                      ?.message
                  }
                </span>
              )}
            </div>

            <div className="flex flex-col items-center gap-2">
              <GoldTicketIcon width={60} height={40} />
              <InputField
                placeholder="Gold tickets count"
                extra="w-[150px]"
                id={`bundle[${index}].giveawayTicketsCount`}
                variant="auth"
                type="text"
                label="Gold tickets count"
                {...form.register(`bundles.${index}.giveawayTicketsCount`, {
                  validate: value =>
                    validatePattern(
                      value.toString(),
                      false,
                      false,
                      true,
                      index
                    ),
                })}
                state={
                  form.formState.errors.bundles?.[index]?.giveawayTicketsCount
                    ? 'error'
                    : ''
                }
              />
              {form.formState.errors.bundles?.[index]?.giveawayTicketsCount && (
                <span className="text-red-500 text-sm">
                  {
                    form.formState.errors.bundles?.[index]?.giveawayTicketsCount
                      ?.message
                  }
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-end gap-2">
              <InputField
                placeholder="TG stars price"
                extra="w-[100px]"
                id={`bundle[${index}].starsPrice`}
                variant="auth"
                type="text"
                label="TG stars price"
                {...form.register(`bundles.${index}.starsPrice`, {
                  required: 'This field is required',
                  pattern: intPattern,
                  min: { message: 'Min is 1', value: 1 },
                })}
                state={
                  form.formState.errors.bundles?.[index]?.starsPrice
                    ? 'error'
                    : ''
                }
              />
              <StarVioletIcon width={30} height={30} className="mb-3" />
            </div>
            {form.formState.errors.bundles?.[index]?.starsPrice && (
              <span className="text-red-500 text-sm">
                {form.formState.errors.bundles?.[index]?.starsPrice?.message}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
