import Card from 'components/card';
import { useMemo, useState } from 'react';
import { PushMessageDialog } from '../PushDialog';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Button } from 'components/button';
import Pagination from 'components/Pagination/Pagination';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdAdd, MdRefresh } from 'react-icons/md';
import useSWR from 'swr';
import { useDebounceValue } from 'usehooks-ts';
import {
  EPushMessageStatus,
  PushMessage,
  pushMessagesService,
} from 'views/services/pushMessagesService';
import { formatDate } from 'utils/date';
import { MessagePopover } from '../MessagePopover';

function MessagesTable() {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [page, setPage] = useState(0);
  const [stopAutoRefresh, setStopAutRefresh] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(
    `/api/notifications/list?page=${page}&limit=${itemsPerPage}`,
    () =>
      pushMessagesService.getPaginatedPushMessages({
        page: page,
        pageSize: itemsPerPage,
      }),
    {
      revalidateOnFocus: !stopAutoRefresh,
    }
  );

  const totalPages = Math.ceil(
    (tableData?.meta?.totalItems ?? 0) / itemsPerPage
  );

  const handleItemsPerPageChange = (items: number) => {
    setItemsPerPage(items);
    setPage(0);
  };

  const handleTaskChange = async () => {
    await mutate();
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('message', {
        id: 'message',
        enableSorting: false,
        header: () => (
          <p className="w-[200px] min-w-[200px] text-sm font-bold text-[#000000] dark:text-white">
            Text
          </p>
        ),
        cell: info => (
          <p className="w-[200px] min-w-[200px] max-w-[200px] truncate text-sm font-bold text-navy-700 dark:text-white">
            <MessagePopover message={info.cell.getValue()} />
          </p>
        ),
      }),
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        enableSorting: false,
        header: () => (
          <p className="w-[120px] text-sm font-bold text-[#000000] dark:text-white">
            Create At
          </p>
        ),
        cell: info => (
          <p className="w-[120px] text-sm font-bold text-navy-700 dark:text-white">
            {formatDate(info.cell.getValue())}
          </p>
        ),
      }),
      columnHelper.accessor('usersSuccess', {
        id: 'usersSuccess',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-[#000000] dark:text-white">
            Completed
          </p>
        ),
        cell: info => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('usersFatal', {
        id: 'usersFatal',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-[#000000] dark:text-white">
            Failed
          </p>
        ),
        cell: info => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.display({
        id: 'usersLeft',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-[#000000] dark:text-white">
            User's Left
          </p>
        ),
        cell: info => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {Math.max(
              0,
              info.row.original.targetedUsers -
                info.row.original.usersSuccess -
                info.row.original.usersFatal
            )}{' '}
          </p>
        ),
      }),

      columnHelper.accessor('isSendingProcessFinished', {
        id: 'isSendingProcessFinished',
        enableSorting: false,
        header: () => (
          <p className="text-sm font-bold text-[#000000] dark:text-white">
            Status
          </p>
        ),
        cell: info => (
          <p className="w-[100px] min-w-[100px] text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()
              ? EPushMessageStatus.done
              : EPushMessageStatus.inProgress}
          </p>
        ),
      }),
    ],
    []
  );

  const [data] = useDebounceValue(tableData?.data ?? [], 100);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await mutate();
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <>
      <div className="mb-8 mt-8 flex items-center justify-between">
        <div className="flex gap-2 ml-auto">
          <PushMessageDialog
            onOpenChange={setStopAutRefresh}
            onSuccess={handleTaskChange}
          >
            <Button>
              <MdAdd />
              Create Push Message
            </Button>
          </PushMessageDialog>
        </div>
      </div>
      <Card extra={'w-full max-h-[63rem] overflow-auto px-6 pb-6 grow'}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Messages
          </div>
          <Button
            className="min-w-[120px] min-h-[40px]"
            onClick={handleRefresh}
            disabled={isRefreshing || isLoading}
          >
            {isRefreshing ? (
              <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin" />
            ) : (
              <>
                <MdRefresh />
                Refresh
              </>
            )}
          </Button>
        </header>

        <div className="mt-8 overflow-x-scroll">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={clsx(
                          'bg-white pb-2 pr-4 pt-4 text-start dark:bg-navy-800',
                          header.column.getCanSort() && 'cursor-pointer'
                        )}
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="flex justify-center py-10">
                      <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading && (tableData?.data?.length ?? 0) <= 0 && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="py-10 text-center font-bold uppercase">
                      No data
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !!tableData &&
                tableData?.data?.length > 0 &&
                table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      className={clsx(
                        '[&_td]:odd:bg-gray-50 [&_td]:odd:dark:bg-navy-900'
                      )}
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[50px] border-white/0 py-2 pr-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          totalItems={tableData?.meta?.totalItems}
          onPageChange={setPage}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </Card>
    </>
  );
}

export default MessagesTable;
const columnHelper = createColumnHelper<PushMessage>();
