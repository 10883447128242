import {
  analiticsService,
  TAnaliticsData,
  TAnaliticsTypes,
} from 'views/services/analitics';
import {
  renderCountriesData,
  renderDevicesData,
  renderGenderData,
  renderLanguagesData,
  renderSelectedCountriesData,
  renderTgPremiumData,
  renderTgStarsData,
  renderTicketsData,
  renderTonsData,
  renderTransactionSourcesData,
  renderUsersFilledBioData,
  renderUtmSourcesData,
  renderWalletsData,
} from './helpers';
import { ExtraAnaliticsFields } from './ExtraAnaliticsFields';
import { TUTMAnalyticsData } from 'views/types/analytics';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { useGiveawayStore } from 'views/store/useGiveawayStore';
import { GiveawayTasks } from './GiveawayTasks';

type TNonAppAnaliticsProps = {
  data: TAnaliticsData | TUTMAnalyticsData;
  type: EAnaliticDataTypeEnum;
  title: string;
  id: string;
  extraAnaliticsType: TAnaliticsTypes;
};

export const NonAppAnalitics = ({
  data,
  type,
  title,
  id,
  extraAnaliticsType,
}: TNonAppAnaliticsProps) => {
  const { totalUsers } = useAnaliticsStore();
  const isGiveaway = EAnaliticDataTypeEnum.giveaway === type;
  const isUtm = type === EAnaliticDataTypeEnum.utm;
  const isTask = type === EAnaliticDataTypeEnum.task;
  const { giveawayList } = useGiveawayStore();
  const giveawayTasks = giveawayList.find(item => item.id === id)?.tasks;

  return (
    <>
      {data && (
        <div className="w-full flex flex-col gap-8 max-h-[80svh] overflow-y-auto mt-4">
          <div>
            <h2 className="text-2xl font-semibold mb-2 text-3xl border-b border-gray-200 dark:!border-white/10 pb-2">
              {isGiveaway ? 'Giveaway Analytics' : 'Analytics'}:
            </h2>
            {!isGiveaway && (
              <h3 className="text-2xl font-semibold mb-8 text-lg">
                <span className="uppercase mr-3">{type}:</span>
                {title}
              </h3>
            )}

            <div className="flex gap-8">
              {'availableUsersCount' in data && (
                <div className="flex gap-2">
                  <h3 className="min-w-[100px] font-bold">Users Available:</h3>
                  <p>{data.availableUsersCount}</p>
                </div>
              )}
              {isGiveaway && (
                <div className="flex gap-2">
                  <h3 className="min-w-[100px] font-bold">Users Available:</h3>
                  <p>{totalUsers}</p>
                </div>
              )}
              <div className="flex gap-2">
                <h3 className="min-w-[100px] font-bold">Users Completed:</h3>
                <p>
                  {'completedUsersCount' in data
                    ? data?.completedUsersCount
                    : data?.totalUsersCount}
                </p>
              </div>
              {isTask && (
                <div className="flex gap-2">
                  <h3 className="min-w-[100px] font-bold">
                    Users In Progress:
                  </h3>
                  <p>
                    {'completedUsersCount' in data
                      ? data?.notCompletedUsersCount
                      : data?.totalUsersCount}
                  </p>
                </div>
              )}
            </div>

            {data.usersAvgTgAge && (
              <div className="flex gap-8">
                <div className="flex gap-2">
                  <h3 className="min-w-[100px] font-bold">Users AVG TG Age:</h3>
                  <p>{`${analiticsService.getDateDifference(data.usersAvgTgAge)}`}</p>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-gray-200 dark:!border-white/10 -mt-4" />
          {isUtm &&
            'purchasedTicketsCount' in data &&
            renderTicketsData({
              totalPurchaseUsers: data.purchasedTicketsCount,
              usersData: data.usersWithTicketsData,
            })}
          {isUtm &&
            'totalTonsSpent' in data &&
            renderTonsData({
              totalTonsSpent: data.totalTonsSpent,
            })}
          {isUtm &&
            'totalTgStarsSpent' in data &&
            renderTgStarsData({
              totalTgStarsSpent: data.totalTgStarsSpent,
            })}
          {renderTransactionSourcesData(data?.transactionSourceData)}
          {renderDevicesData(data.devicesData)}
          {renderGenderData(data.genderData)}
          {renderCountriesData(data.countriesData)}
          {renderSelectedCountriesData(
            data.selectedCountriesData,
            'completedUsersCount' in data
              ? data?.completedUsersCount
              : data?.totalUsersCount
          )}
          {renderLanguagesData(data.languagesData)}
          {renderTgPremiumData(
            data.tgPremiumData,
            'completedUsersCount' in data
              ? data?.completedUsersCount
              : data?.totalUsersCount
          )}
          {data.utmSourcesData &&
            renderUtmSourcesData(
              data.utmSourcesData,
              'completedUsersCount' in data
                ? data?.completedUsersCount
                : data?.totalUsersCount
            )}
          {renderWalletsData(
            data.walletsData,
            'completedUsersCount' in data
              ? data?.completedUsersCount
              : data?.totalUsersCount
          )}
          {renderUsersFilledBioData(
            data.usersFilledBioData,
            'completedUsersCount' in data
              ? data?.completedUsersCount
              : data?.totalUsersCount
          )}
          <ExtraAnaliticsFields
            resourceId={id}
            type={extraAnaliticsType}
            totalUsers={
              'completedUsersCount' in data
                ? data?.completedUsersCount
                : data?.totalUsersCount
            }
          />
          {giveawayTasks && isGiveaway && (
            <GiveawayTasks tasks={giveawayTasks} giveawayId={id} />
          )}
        </div>
      )}
    </>
  );
};
