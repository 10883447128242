import { formatNumber } from 'utils/formatNumber';
import { UserCountAndPercent } from './UserCountAndPercent';
import {
  TCountryData,
  TGenderData,
  TLanguageData,
  TUtmSourceData,
} from 'views/types/analytics';
import {
  TCountPercentData,
  TDateStatistics,
  TDevicesData,
  TransactionSourceData,
} from 'views/services/analitics';
import { FieldWithChart } from './FieldWithChart';

export const renderGenderData = (genderData: TGenderData) => (
  <div className="flex gap-20 w-full">
    <h3 className="min-w-[120px] max-w-[120px] font-bold">Gender:</h3>

    <div className="flex flex-col gap-1">
      <p>
        <span className="mr-4 font-bold">Male:</span>
        {formatNumber(genderData.maleCount)} (
        {formatNumber(genderData.malePercent)}%)
      </p>
      <p>
        <span className="mr-4 font-bold">Female:</span>
        {formatNumber(genderData.femaleCount)} (
        {formatNumber(genderData.femalePercent)}%)
      </p>
      <p>
        <span className="mr-4 font-bold">Other:</span>
        {formatNumber(genderData.otherGenderCount)} (
        {formatNumber(genderData.otherGenderPercent)}%)
      </p>
    </div>
  </div>
);

export const renderTransactionSourcesData = (data: TransactionSourceData) =>
  data && (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">
        TON/Stars Completions:
      </h3>
      <div className="grid gap-4 grid-cols-2">
        <div className="flex flex-col gap-1 w-[200px]">
          <p className="font-bold">Stars:</p>
          <UserCountAndPercent
            count={data.totalTgStarsTransactions}
            percent={data.totalTgStarsTransactionsPercent}
          />
        </div>

        <div className="flex flex-col gap-1 w-[200px]">
          <p className="font-bold">TON:</p>
          <UserCountAndPercent
            count={data.totalTonTransactions}
            percent={data.totalTonTransactionsPercent}
          />
        </div>
      </div>
    </div>
  );

export const renderCountriesData = (countriesData: TCountryData[]) => {
  const sortedCountriesData = countriesData.sort(
    (a, b) => b.usersCount - a.usersCount
  );
  return (
    <div className="flex gap-20 w-full border-b border-gray-200 dark:!border-white/10 pb-8">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">Countries:</h3>

      {countriesData.length > 0 && (
        <FieldWithChart data={sortedCountriesData} />
      )}
    </div>
  );
};

export const renderSelectedCountriesData = (
  selectedCountriesData: TCountryData[],
  totalCompletedUsers: number
) => {
  const nonSelectedCountriesUsers =
    totalCompletedUsers -
    selectedCountriesData.reduce((acc, country) => acc + country.usersCount, 0);
  const nonSelectedCountriesUsersPercent =
    100 -
    selectedCountriesData.reduce(
      (acc, country) => acc + country.usersPercent,
      0
    );
  const sortedSelectedCountriesData = selectedCountriesData.sort(
    (a, b) => b.usersCount - a.usersCount
  );

  if (nonSelectedCountriesUsers > 0) {
    sortedSelectedCountriesData.push({
      name: 'Without selected country',
      usersCount: nonSelectedCountriesUsers,
      usersPercent: nonSelectedCountriesUsersPercent,
    });
  }

  return (
    <div className="flex gap-20 w-full border-b border-gray-200 dark:!border-white/10 pb-8">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">
        Selected Country (Bio):
      </h3>

      {selectedCountriesData.length > 0 ? (
        <FieldWithChart data={sortedSelectedCountriesData} />
      ) : (
        <p>No data</p>
      )}
    </div>
  );
};

export const renderLanguagesData = (languagesData: TLanguageData[]) => {
  const sortedLanguagesData = languagesData.sort(
    (a, b) => b.usersCount - a.usersCount
  );
  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">Languages:</h3>

      {languagesData.length > 0 && (
        <FieldWithChart data={sortedLanguagesData} />
      )}
    </div>
  );
};

export const renderTgPremiumData = (
  tgPremData: TCountPercentData,
  totalUsers: number
) => {
  const nonTgPremUsers = totalUsers - tgPremData.usersCount;
  const nonTgPremUsersPercent = 100 - tgPremData.usersPercent;
  return (
    <div>
      <div className="flex gap-20 w-full">
        <h3 className="min-w-[120px] max-w-[120px] font-bold">Premium:</h3>
        <div className="grid gap-4 grid-cols-2">
          <div className="w-[200px]">
            <h4 className="font-bold">TG premium</h4>
            <UserCountAndPercent
              count={tgPremData.usersCount}
              percent={tgPremData.usersPercent}
            />
          </div>

          <div className="w-[200px]">
            <h4 className="font-bold">Without TG premium</h4>
            <UserCountAndPercent
              count={nonTgPremUsers}
              percent={nonTgPremUsersPercent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderUtmSourcesData = (
  utmSourcesData: TUtmSourceData[],
  totalCompletedUsers: number
) => {
  const usersWithoutUtm =
    totalCompletedUsers -
    utmSourcesData?.reduce((acc, source) => acc + source.usersCount, 0);
  const usersWithoutUtmPercent =
    100 - utmSourcesData?.reduce((acc, source) => acc + source.usersPercent, 0);

  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">UTM Sources:</h3>

      <div className="grid gap-4 grid-cols-2">
        {utmSourcesData?.map((source, index) => (
          <div key={index} className="w-[200px]">
            <p className="font-bold">{source.name}</p>
            <UserCountAndPercent
              count={source.usersCount}
              percent={source.usersPercent}
            />
          </div>
        ))}

        <div className="w-[200px]">
          <p className="font-bold">Another Traffic</p>
          <UserCountAndPercent
            count={usersWithoutUtm}
            percent={usersWithoutUtmPercent}
          />
        </div>
      </div>
    </div>
  );
};

export const renderWalletsData = (
  walletsData: TCountPercentData,
  totalCompletedUsers: number
) => {
  const usersWithoutWallet = totalCompletedUsers - walletsData.usersCount;
  const usersWithoutWalletPercent = 100 - walletsData.usersPercent;

  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">Wallets:</h3>
      <div className="grid gap-4 grid-cols-2">
        <div className="flex flex-col gap-1 w-[200px]">
          <p className="font-bold">With wallet:</p>
          <UserCountAndPercent
            count={walletsData.usersCount}
            percent={walletsData.usersPercent}
          />
        </div>

        <div className="flex flex-col gap-1 w-[200px]">
          <p className="font-bold">Without wallet:</p>
          <UserCountAndPercent
            count={usersWithoutWallet}
            percent={usersWithoutWalletPercent}
          />
        </div>
      </div>
    </div>
  );
};

export const renderDevicesData = (devicesData: TDevicesData) => {
  return (
    <div className="flex gap-20 w-full">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">System:</h3>

      <div className="grid gap-4 grid-cols-2">
        <div className="w-[200px]">
          <h4 className="font-bold">iOS</h4>
          <UserCountAndPercent
            count={devicesData.iosCount}
            percent={devicesData.iosPercent}
          />
        </div>

        <div className="w-[200px]">
          <h4 className="font-bold">Android</h4>
          <UserCountAndPercent
            count={devicesData.androidCount}
            percent={devicesData.androidPercent}
          />
        </div>

        <div className="w-[200px]">
          <h4 className="font-bold">Other platforms</h4>
          <UserCountAndPercent
            count={devicesData.otherPlatformsCount}
            percent={devicesData.otherPlatformsPercent}
          />
        </div>
      </div>
    </div>
  );
};

export const renderUsersFilledBioData = (
  usersFilledBioData: TCountPercentData,
  totalUsers: number
) => {
  const usersWithoutBio = totalUsers - usersFilledBioData.usersCount;
  const usersWithoutBioPercent = 100 - usersFilledBioData.usersPercent;

  return (
    <div className="flex gap-20 w-full border-b border-gray-200 dark:!border-white/10 pb-4">
      <h3 className="min-w-[120px] max-w-[120px] font-bold">
        Users filled bio:
      </h3>
      <div className="flex flex-col gap-1">
        <p>
          <span className="mr-4 font-bold">Filled bio:</span>
          {formatNumber(usersFilledBioData.usersCount)} (
          {formatNumber(usersFilledBioData.usersPercent)}%)
        </p>
        <p>
          <span className="mr-4 font-bold">Not filled Bio:</span>
          {formatNumber(usersWithoutBio)} (
          {formatNumber(usersWithoutBioPercent)}%)
        </p>
      </div>
    </div>
  );
};

export const renderTicketsData = ({
  totalPurchaseUsers,
  usersData,
}: {
  totalPurchaseUsers: number;
  usersData: TCountPercentData;
}) => {
  return (
    <div className="w-full border-b border-gray-200 dark:!border-white/10 pb-8">
      <h4 className="text-xl font-bold">Ticket Statistic:</h4>

      <div className="flex gap-2 mt-4">
        <p className=" font-bold">Purchased Tickets Amount:</p>
        <p>{totalPurchaseUsers}</p>
      </div>

      <div className="flex gap-20 w-full mt-2">
        <h4 className="min-w-[120px] max-w-[120px] font-bold">
          Ticket Buyers Amount:
        </h4>
        <div>
          <UserCountAndPercent
            count={usersData.usersCount}
            percent={usersData.usersPercent}
          />
        </div>
      </div>
    </div>
  );
};

export const renderTonsData = ({
  totalTonsSpent,
}: {
  totalTonsSpent: number;
}) => {
  return (
    <div className="w-full border-b border-gray-200 dark:!border-white/10 pb-8">
      <h4 className="text-xl font-bold">TON Coins Statistic:</h4>

      <div className="flex gap-2 mt-4">
        <p className=" font-bold">Total Spent:</p>
        <p>{totalTonsSpent?.toFixed(2)}</p>TON
      </div>
    </div>
  );
};

export const renderTgStarsData = ({
  totalTgStarsSpent,
}: {
  totalTgStarsSpent: number;
}) => {
  return (
    <div className="w-full border-b border-gray-200 dark:!border-white/10 pb-8">
      <h4 className="text-xl font-bold">Telegram Stars Statistic:</h4>

      <div className="flex gap-2 mt-4">
        <p className=" font-bold">Total Spent:</p>
        <p>{totalTgStarsSpent}</p>STARS
      </div>
    </div>
  );
};

type TGroupedData = {
  [key: string]: TDateStatistics[];
};

export const formatDataForRecharts = (data: TDateStatistics[]) => {
  const result = [];
  const groupedData: TGroupedData = data.reduce((acc, item) => {
    const date = new Date(item.date).toISOString().split('T')[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {} as TGroupedData);

  for (const date in groupedData) {
    const items = groupedData[date];
    const dateObj = new Date(date);
    const day = dateObj.getUTCDate().toString().padStart(2, '0');
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getUTCFullYear().toString();
    const currentDate = `${day}:${month}:${year}`;

    const hoursArray = new Array(24).fill(0).map((_, index) => ({
      name: `${index.toString().padStart(2, '0')}:00`,
      usersCount: 0,
      date: date,
    }));

    items.forEach(item => {
      const date = new Date(item.date);
      const hours = date.getUTCHours();
      hoursArray[hours] = {
        name: `${hours.toString().padStart(2, '0')}:00`,
        usersCount: item.usersCount,
        date: item.date.split('T')[0],
      };
    });

    hoursArray[0].name = `(${currentDate}) ${hoursArray[0].name}`;

    result.push(...hoursArray);
  }

  return result;
};
