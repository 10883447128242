import { toast } from 'App';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { checkImage, MAX_FILE_SIZE_BYTES } from 'utils/image';
import { giveawayService } from 'views/services/giveawayService';
import { TBundle } from 'views/types/popup';

export const PopUpImage = () => {
  const [isPending, setIsPending] = useState(false);

  const form = useFormContext<TBundle>();
  const imageUrl = form.watch('shopQuest.imageUrl');

  const uploadImage = async (image: File) => {
    try {
      await checkImage(image);
    } catch (error) {
      console.error(error);
      toast({
        title: 'Invalid image format',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });
    }

    const formData = new FormData();
    formData.append('files', image, image.name);

    try {
      setIsPending(true);
      const url = await giveawayService.uploadAdminURL(formData);

      form.setValue('shopQuest.imageUrl', url, { shouldDirty: true });
    } finally {
      setIsPending(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;

    if (file.size > MAX_FILE_SIZE_BYTES) {
      toast({
        title: 'Image must be no larger than 5 MB',
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });

      return;
    }

    uploadImage(file);
  };

  useEffect(() => {
    if (!imageUrl && form.formState.isDirty) {
      form.setError('shopQuest.imageUrl', { message: 'Image is required' });
    } else {
      form.clearErrors('shopQuest.imageUrl');
    }
  }, [imageUrl, form]);

  return (
    <div className="border border-gray-200 dark:border-gray-700 p-4 rounded-xl">
      <div>
        <h4 className="mb-2 font-bold">Image: {isPending && ' Loading...'}</h4>
        {!!imageUrl && (
          <img
            className={clsx(
              'w-[150px] h-[150px] object-contain rounded-lg',
              isPending && 'opacity-50'
            )}
            src={imageUrl}
            alt="popup background"
          />
        )}

        <input
          name="shopQuest.imageUrl"
          placeholder="Image URL"
          type="file"
          id={''}
          onChange={handleInputChange}
          disabled={isPending}
        />
      </div>

      {form.formState.errors.shopQuest?.imageUrl && (
        <p className="text-red-500 text-sm mt-4">
          {form.formState.errors.shopQuest.imageUrl.message}
        </p>
      )}
    </div>
  );
};
