import Card from 'components/card';
import { useMemo, useState } from 'react';
import { ButtonSM } from '../../../../../components/button/Small';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Button } from 'components/button';
import Pagination from 'components/Pagination/Pagination';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import useSWR from 'swr';
import { useDebounceValue } from 'usehooks-ts';
import { utmService } from 'views/services/utmService';
import { FaRegCopy } from 'react-icons/fa6';
import { toast } from 'App';
import { UTMSourceDialog } from '../UTMSourceDialog';
import { TUMSourceInfluencer } from 'views/types/utm';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { DeleteButton } from 'components/deleteButton';

function UTMSourcesTable() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [searchDebounce] = useDebounceValue(search, 1000);
  const [stopAutoRefresh, setStopAutRefresh] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { setAnalitic } = useAnaliticsStore();

  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(
    `/utm-sources?page=${page}&search=${searchDebounce}&limit=${itemsPerPage}`,
    () =>
      utmService.getPaginatedUTMSources({
        page: page,
        pageSize: itemsPerPage,
        searchValue: searchDebounce,
        isInfluencersSources: false,
      }),
    {
      revalidateOnFocus: !stopAutoRefresh,
    }
  );

  const totalPages = Math.ceil(
    (tableData?.meta?.totalItems ?? 0) / itemsPerPage
  );

  const handleItemsPerPageChange = (items: number) => {
    setItemsPerPage(items);
    setPage(0);
  };

  const handleUTMChange = async () => {
    await mutate();
  };

  const copyToClipboard = (link: string) => {
    navigator.clipboard.writeText(link).then(() => {
      toast({
        title: 'The link has been copied',
        status: 'success',
        isClosable: true,
        position: 'top-right',
      });
    });
  };

  const handleAnaliticsModal = (id: string, title: string) => {
    setAnalitic(EAnaliticDataTypeEnum.utm, id, title);
  };

  const handleDeleteUtm = async (id: string) => {
    await utmService.deleteUTMSource(id);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        enableSorting: false,
        header: () => (
          <p className="w-[200px] min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
            Name
          </p>
        ),
        cell: info => (
          <p className="w-[200px] min-w-[200px] truncate text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue()}
          </p>
        ),
      }),
      columnHelper.accessor('name', {
        id: 'name',
        enableSorting: false,
        header: () => (
          <p className="min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
            URL parameter
          </p>
        ),
        cell: info => (
          <p className="min-w-[300px] truncate text-sm font-bold text-gray-500">
            <span className="text-navy-700 dark:text-white">
              {`${process.env.REACT_APP_BOT_LINK}?startapp=utmSource-${info.cell.getValue()}`}
            </span>
          </p>
        ),
      }),
      columnHelper.accessor('amountOfLeads', {
        id: 'amountOfLeads',
        enableSorting: false,
        header: () => (
          <p className="w-[200px] min-w-[200px] text-sm font-bold text-gray-600 dark:text-white">
            Amount of leads
          </p>
        ),
        cell: info => (
          <p className="w-[200px] min-w-[200px] truncate text-sm font-bold text-navy-700 dark:text-white">
            {info.cell.getValue() || 0}
          </p>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
            Actions
          </p>
        ),
        cell: info => (
          <div className="flex gap-3 font-bold items-center">
            <FaRegCopy
              className="cursor-pointer"
              onClick={() =>
                copyToClipboard(
                  `${process.env.REACT_APP_BOT_LINK}?startapp=utmSource-${info.row.original.name}`
                )
              }
            />
            <UTMSourceDialog
              onOpenChange={val => setStopAutRefresh(val)}
              onSuccess={handleUTMChange}
              utm={info.row.original}
            >
              <ButtonSM variant="primary">Edit</ButtonSM>
            </UTMSourceDialog>
            <ButtonSM
              variant="primary"
              onClick={() =>
                handleAnaliticsModal(
                  info.row.original.id,
                  info.row.original.name
                )
              }
            >
              Analytics
            </ButtonSM>
            <DeleteButton
              action={() => handleDeleteUtm(info.row.original.id)}
              onSuccess={handleUTMChange}
            />
          </div>
        ),
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [data] = useDebounceValue(tableData?.data ?? [], 100);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <div className="mb-8 mt-8 flex items-center justify-between">
        <div className="flex gap-2 ml-auto">
          <UTMSourceDialog
            onOpenChange={setStopAutRefresh}
            onSuccess={handleUTMChange}
          >
            <Button>
              <MdAdd />
              Create UTM Source
            </Button>
          </UTMSourceDialog>
        </div>
      </div>
      <Card extra={'w-full max-h-[63rem] overflow-auto px-6 pb-6 grow'}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            UTM Sources
          </div>
          <input
            type="text"
            placeholder="Search by name"
            className="block min-h-7 w-full grow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit sm:max-w-96"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              setPage(0);
            }}
          />
        </header>
        <div className="mt-8 overflow-x-auto">
          <table className="w-full">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map(headerGroup => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map(header => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className={clsx(
                          'bg-lightPrimary  pb-2 pr-4 pt-4 text-start dark:bg-[#030a27]',
                          header.column.getCanSort() && 'cursor-pointer',
                          'first:rounded-l-lg last:rounded-r-lg first:pl-4'
                        )}
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="flex justify-center py-10">
                      <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading && (tableData?.data?.length ?? 0) <= 0 && (
                <tr>
                  <td
                    className="absolute left-1/2 -translate-x-1/2"
                    colSpan={8}
                  >
                    <p className="py-10 text-center font-bold uppercase">
                      No data
                    </p>
                  </td>
                </tr>
              )}
              {!isLoading &&
                !!tableData &&
                tableData?.data?.length > 0 &&
                table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      className="[&_td]:even:bg-gray-50 [&_td]:even:dark:bg-navy-900"
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="min-w-[50px] border-white/0 py-2 pr-4 first:pl-4 first:rounded-l-lg last:rounded-r-lg last:pr-0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          totalItems={tableData?.meta?.totalItems}
          onPageChange={setPage}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </Card>
    </>
  );
}
export default UTMSourcesTable;
const columnHelper = createColumnHelper<TUMSourceInfluencer>();
