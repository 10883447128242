import { $api } from './interceptor';

export type TCountryData = {
  name: string;
  usersCount: number;
  usersPercent: number;
};

export type TLanguageData = {
  name: string;
  usersCount: number;
  usersPercent: number;
};

export type TUtmSourceData = {
  name: string;
  usersCount: number;
  usersPercent: number;
};

export type TGenderData = {
  maleCount: number;
  femaleCount: number;
  otherGenderCount: number;
  malePercent: number;
  femalePercent: number;
  otherGenderPercent: number;
};

export type TCountPercentData = {
  usersCount: number;
  usersPercent: number;
};

export type TransactionSourceData = {
  totalTonTransactions: number;
  totalTgStarsTransactions: number;
  totalTonTransactionsPercent: number;
  totalTgStarsTransactionsPercent: number;
};

export type TDevicesData = {
  iosCount: number;
  androidCount: number;
  otherPlatformsCount: number;
  iosPercent: number;
  androidPercent: number;
  otherPlatformsPercent: number;
};

export type TAnaliticsData = {
  availableUsersCount: number;
  completedUsersCount: number;
  notCompletedUsersCount: number;
  genderData: TGenderData;
  countriesData: TCountryData[];
  languagesData: TLanguageData[];
  tgPremiumData: TCountPercentData;
  utmSourcesData: TUtmSourceData[];
  walletsData: TCountPercentData;
  usersFilledBioData: TCountPercentData;
  devicesData: TDevicesData;
  selectedCountriesData: TCountryData[];
  usersAvgTgAge: string | null;
  transactionSourceData: TransactionSourceData;
};

type TRewardItem = {
  type: string;
  amount: number;
  statsCount: number;
  statsAmount: number;
};

type TRewardAnalitycs = {
  USDT: TRewardItem[];
  TICKETS: TRewardItem[];
  STARS: TRewardItem[];
};

export type TWheelAnaliticsData = {
  usersTotalCount: number;
  gamesTotalCount: number;
  avgGamesPerUser: number;
  rewardAnalytics: TRewardAnalitycs;
};

export type TPuzzleAnaliticsData = {
  totalUsersCompletedToday: number;
  totalUsersNotCompletedToday: number;
  totalUsersSpentTicketToday: number;
  totalUsersCompletedGeneral: number;
  totalUsersNotCompletedGeneral: number;
  totalUsersSpentTicketGeneral: number;
};

export type TDateStatistics = {
  date: string;
  usersCount: number;
};

export type TExtraAnaliticsDataTypes =
  | 'age'
  | 'points'
  | 'accountAge'
  | 'completedTasks'
  | 'referrals';

export type TAnaliticsTypes = 'task' | 'giveaway' | 'utmSource' | 'empty';

type TExtraAnaliticsOptions = {
  dataType: TExtraAnaliticsDataTypes;
  analityctsType: TAnaliticsTypes;
  itemId: string;
  from?: number;
  to?: number | '';
};

type TWheelAnaliticsDataResponse = {
  avgGamesPerUser: number;
  totalGames: number;
  uniqueUsers: number;
  totalCoinsAmount: number;
  totalCoinsGamesCount: number;
  total500CoinsAmount: number;
  total500CoinsGamesCount: number;
  total1000CoinsAmount: number;
  total1000CoinsGamesCount: number;
  total2000CoinsAmount: number;
  total2000CoinsGamesCount: number;
  totalUsdtAmount: number;
  totalUsdtGamesCount: number;
  total002UsdtAmount: number;
  total002UsdtGamesCount: number;
  total1UsdtAmount: number;
  total1UsdtGamesCount: number;
  total100UsdtAmount: number;
  total100UsdtGamesCount: number;
  totalTicketsGamesCount: number;
  totalTicketsAmount: number;
  total1TicketsGamesCount: number;
  total1TicketsAmount: number;
  total5TicketsGamesCount: number;
  total5TicketsAmount: number;
};

const analiticsApi = () => {
  const getExtraAnalitics = async (options: TExtraAnaliticsOptions) => {
    const idOption =
      options.analityctsType === 'empty'
        ? ''
        : `&${options.analityctsType}Id=${options.itemId}`;
    const { data } = await $api.get<{ usersCount: number }>(
      `/api/users/specified-statistic?dataType=${options.dataType}${idOption}&fromValue=${options.from ?? ''}&toValue=${options.to ?? ''}`
    );
    return data;
  };

  const getGiveawayStats = async (id: string) => {
    const { data } = await $api.get<TAnaliticsData>(
      `/api/giveaways/stats/${id}`
    );
    return data;
  };

  const getWheelStats = async () => {
    const { data } =
      await $api.get<TWheelAnaliticsDataResponse>(`/api/roulette/stat`);

    const mappedData: TWheelAnaliticsData = {
      usersTotalCount: data.uniqueUsers,
      gamesTotalCount: data.totalGames,
      avgGamesPerUser: data.avgGamesPerUser,
      rewardAnalytics: {
        USDT: [
          {
            type: 'USDT',
            statsCount: data.total002UsdtGamesCount,
            amount: 0.02,
            statsAmount: data.total002UsdtAmount,
          },
          {
            type: 'USDT',
            amount: 1,
            statsCount: data.total1UsdtGamesCount,
            statsAmount: data.total1UsdtAmount,
          },
          {
            type: 'USDT',
            amount: 100,
            statsCount: data.total100UsdtGamesCount,
            statsAmount: data.total100UsdtAmount,
          },
        ],
        STARS: [
          {
            type: 'STARS',
            amount: 500,
            statsCount: data.total500CoinsGamesCount,
            statsAmount: data.total500CoinsAmount,
          },
          {
            type: 'STARS',
            amount: 1000,
            statsCount: data.total1000CoinsGamesCount,
            statsAmount: data.total1000CoinsAmount,
          },
          {
            type: 'STARS',
            amount: 2000,
            statsCount: data.total2000CoinsGamesCount,
            statsAmount: data.total2000CoinsAmount,
          },
        ],
        TICKETS: [
          {
            type: 'TICKETS',
            amount: 1,
            statsCount: data.total1TicketsGamesCount,
            statsAmount: data.total1TicketsAmount,
          },
          {
            type: 'TICKETS',
            amount: 5,
            statsCount: data.total5TicketsGamesCount,
            statsAmount: data.total5TicketsAmount,
          },
        ],
      },
    };

    return mappedData;
  };

  const getPuzzleStats = async () => {
    const { data } = await $api.get<TPuzzleAnaliticsData>(
      `/api/secret-combo/stat`
    );

    return data;
  };

  const getDailyStats = async (startDate: Date, endDate: Date) => {
    const formattedFromDate = startDate.toISOString();
    const formattedToDate = endDate.toISOString();
    const { data } = await $api.get<TDateStatistics[]>(
      `/api/users/daily-statistic?startDate=${formattedFromDate}&endDate=${formattedToDate}`
    );

    return data;
  };

  const getTasksStats = async (id: string) => {
    const { data } = await $api.get<TAnaliticsData>(`/api/tasks/stat/${id}`);
    return data;
  };

  const getDateDifference = (date: string) => {
    const now = new Date();
    const then = new Date(date);
    const diff = now.getTime() - then.getTime();
    const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(months / 12);
    const finalMonths = months - years * 12;

    return `${years} y. ${finalMonths} m.`;
  };

  const convertToTimestamp = (dateString: string): number => {
    return new Date(dateString).getTime();
  };

  return {
    getExtraAnalitics,
    getGiveawayStats,
    getTasksStats,
    getPuzzleStats,
    getWheelStats,
    getDailyStats,
    getDateDifference,
    convertToTimestamp,
  };
};

export const analiticsService = analiticsApi();
