import InputField from 'components/fields/InputField';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { TDateStatistics } from 'views/services/analitics';
import { useAnaliticsStore } from 'views/store/useAnaliticsStore';
import { formatDataForRecharts } from './helpers';
import { formatNumber, formatNumberLetters } from 'utils/formatNumber';

type TChartData = {
  name: string;
  usersCount: number;
  date: string;
};

type TTooltipPayload = {
  name: string;
  value: number;
  payload: TChartData;
};

type TCustomTooltipProps = {
  active?: boolean;
  payload?: TTooltipPayload[];
};

const CustomTooltip: React.FC<TCustomTooltipProps> = props => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="border border-gray-200 dark:!border-white/10 shadow-lg bg-white dark:bg-gray-800 boxShadow-darkinset p-4 rounded-xl">
        <div className="flex flex-col gap-2 w-max">
          <p className="flex gap-2">
            <span>Users count:</span>
            <span className="font-bold ml-auto">
              {formatNumber(data.usersCount)}
            </span>
          </p>

          <p className="flex gap-2">
            <span>Time:</span>
            <span className="font-bold ml-auto">{data.name}</span>
          </p>

          <p className="flex gap-2">
            <span>Date:</span>
            <span className="font-bold ml-auto">{data.date}</span>
          </p>
        </div>
      </div>
    );
  }

  return null;
};

type TProps = {
  data: TDateStatistics[];
};

export const HAUStatistics = ({ data }: TProps) => {
  const { startDailyDate, setStartDailyDate, setEndDailyDate } =
    useAnaliticsStore();

  const handleStartDailyDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const targetDate = new Date(value);

    if (isNaN(targetDate.getTime())) {
      console.error('Invalid date:', value);
      return;
    }

    targetDate.setUTCHours(0, 0, 0, 0);
    const endDate = new Date(value);
    endDate.setUTCHours(23, 59, 59, 999);
    setStartDailyDate(targetDate);
    setEndDailyDate(endDate);
  };

  const formatedStartDailyDate = new Date(startDailyDate)
    .toISOString()
    .split('T')[0];

  const averageUsers =
    data.reduce((acc, item) => acc + item.usersCount, 0) / data.length;

  return (
    <div className="w-full">
      <div>
        <h2 className="text-2xl font-bold mb-2 ml-8">Hourly Active User’s:</h2>
        <h3 className="text-xl font-bold mb-6 ml-8">
          Average Users: {Math.ceil(averageUsers || 0)}
        </h3>
      </div>

      <InputField
        type="date"
        value={formatedStartDailyDate}
        id="startDailyDate"
        extra="w-fit mx-auto mb-4"
        placeholder="Enter start date"
        variant=""
        onChange={handleStartDailyDateChange}
        onKeyDown={e => e.preventDefault()}
        onKeyUp={e => e.preventDefault()}
      />

      <div>
        <BarChart width={730} height={500} data={formatDataForRecharts(data)}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            allowDecimals={false}
            tickFormatter={formatNumberLetters}
            scale="time"
          />
          <Bar dataKey="usersCount" fill="#2111A5" minPointSize={5} />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: '#422afb8c' }} />
        </BarChart>
      </div>
    </div>
  );
};
