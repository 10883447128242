import {
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Popover,
  PopoverTrigger,
} from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/portal';

type TProps = {
  message: string;
};

const maxTextPreviewLength = 25;

export const MessagePopover = ({ message }: TProps) => {
  const text =
    message.length > maxTextPreviewLength
      ? `${message.slice(0, maxTextPreviewLength)}...`
      : message;

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <button className="flex cursor-pointer gap-1">{text}</button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent className="border border-gray-200 dark:!border-white/10 overflow-y-auto max-w-[30vw] max-h-[50svh] w-fit bg-white dark:bg-navy-900 p-4 relative shadow-xl rounded-lg">
          <PopoverArrow />
          <PopoverBody className="flex flex-col gap-2 divide-y">
            {message}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
