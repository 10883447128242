import { toast } from 'App';
import { Button } from 'components/button';
import InputField from 'components/fields/InputField';
import Switch from 'components/switch';
import { useEffect, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { checkImage, MAX_FILE_SIZE_BYTES } from 'utils/image';

type TaskFormScheme = {
  task: {
    message: string;
    images: File[];
    inactiveDays?: number;
    withoutGiveawayOnly?: boolean;
    buttonUrl?: string;
    buttonText?: string;
  };
};

type TaskFormErrors = {
  errors: {
    task?: {
      message?: FieldError;
      images?: FieldError;
      inactiveDays?: FieldError;
      buttonUrl?: FieldError;
      buttonText?: FieldError;
    };
  };
  task?: {
    message?: FieldError;
    images?: FieldError;
    inactiveDays?: FieldError;
  };
};

export const MessForm = () => {
  const { register, setValue, watch, formState } =
    useFormContext<TaskFormScheme>();
  const [withButton, setWithButton] = useState(false);
  const pushError = (message: string) => {
    toast({
      title: 'Error',
      description: message,
      status: 'error',
      position: 'top-right',
    });
  };

  if (!register || !formState) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const { errors } = formState as TaskFormErrors;
  const messageError = errors.task?.message?.message as string | undefined;
  const inactiveDaysError = errors.task?.inactiveDays?.message as
    | string
    | undefined;
  const imagesError = errors.task?.images?.message as string | undefined;
  const buttonUrlError = errors.task?.buttonUrl?.message as string | undefined;
  const buttonTextError = errors.task?.buttonText?.message as
    | string
    | undefined;

  const imagesValue = watch('task.images');

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const currentImages = imagesValue || [];
      const newFiles = Array.from(files);

      const validFiles: File[] = [];
      const invalidFiles: string[] = [];

      for (const file of newFiles) {
        if (file.size > MAX_FILE_SIZE_BYTES) {
          pushError('Images must be no larger than 5 MB');
          continue;
        }

        try {
          await checkImage(file);
          validFiles.push(file);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
          invalidFiles.push(file.name);
        }
      }

      if (invalidFiles.length > 0) {
        pushError(
          'Invalid file format. Only images (JPEG, JPG, JFIF, JPE, PNG, WEBP) are allowed!'
        );
      }

      const combinedFiles = [...currentImages, ...validFiles];

      if (combinedFiles.length > 5) {
        pushError('Only the first 5 files will be kept');
      }

      setValue('task.images', combinedFiles.slice(0, 5));

      e.target.value = '';
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...(imagesValue || [])];
    updatedImages.splice(index, 1);
    setValue('task.images', updatedImages);
  };

  useEffect(() => {
    if (!withButton) {
      setValue('task.buttonUrl', '');
      setValue('task.buttonText', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withButton]);

  return (
    <fieldset className="mb-[15px] flex flex-col">
      <label htmlFor="message" className="mb-2 font-medium">
        Message
      </label>
      <textarea
        id="message"
        className="h-40 p-2 border rounded outline-none bg-white/0 border-gray-200 dark:!border-white/10"
        placeholder="Enter your message here"
        {...register('task.message', { required: 'Message is required' })}
      />
      {messageError && (
        <span className="text-sm text-red-500 dark:text-red-400 mt-2">
          {messageError}
        </span>
      )}
      <label htmlFor="images" className="mt-4 mb-2 font-medium">
        Attach Files
      </label>
      <Button className="w-[120px] h-[38px] relative">
        <label
          htmlFor="images"
          className="absolute w-full h-full flex items-center justify-center cursor-pointer"
        >
          Choose files
        </label>
      </Button>
      <input
        id="images"
        type="file"
        accept=".jpg,.jpeg,.png,.webp,.jfif,.jpe"
        multiple
        onChange={handleFileChange}
        className="hidden"
      />
      {imagesError && (
        <span className="text-sm text-red-500 dark:text-red-400 mt-2">
          {imagesError}
        </span>
      )}
      <div className="flex space-x-4 mt-4">
        {imagesValue?.map((image: File, index: number) => (
          <div key={index} className="relative">
            <img
              src={URL.createObjectURL(image)}
              alt={`preview-${index}`}
              className="w-20 h-20 object-cover rounded"
            />
            <button
              type="button"
              onClick={() => handleRemoveImage(index)}
              className="absolute h-6 w-6 -top-2 -right-2 bg-red-500 text-white p-2 rounded-full text-[10px] leading-[10px] font-bold"
            >
              X
            </button>
          </div>
        ))}
      </div>
      <div className="flex gap-1 items-center">
        Only for Users with Last Activity in :
        <input
          id="message"
          className=" p-2 border rounded outline-none bg-white/0 border-gray-200 dark:!border-white/10 w-[80px] h-[36px]"
          placeholder=""
          {...register('task.inactiveDays', {
            required: false,
            pattern: {
              value: /^[0-9]+$/,
              message: 'Please enter only numbers',
            },
          })}
        />
        days
      </div>
      {inactiveDaysError && (
        <span className="text-sm text-red-500 dark:text-red-400 mt-2">
          {inactiveDaysError}
        </span>
      )}
      <div className="flex gap-2 items-center mt-[16px]">
        Only for Users without Completed Giveaway
        <input
          type="checkbox"
          className="h-6 w-6"
          {...register('task.withoutGiveawayOnly', {
            required: false,
          })}
        />
      </div>

      <div>
        <label
          htmlFor="addButton"
          className="flex items-center gap-4 cursor-pointer mt-4 w-fit"
        >
          <Switch
            id="addButton"
            checked={withButton}
            onChange={e => setWithButton(e.target.checked)}
          />
          Add Button
        </label>

        {withButton && (
          <div className="mt-3 flex flex-col gap-1">
            <InputField
              id="buttonUrl"
              extra=""
              variant=""
              state={buttonUrlError ? 'error' : ''}
              placeholder="Enter button URL"
              {...register('task.buttonUrl', {
                required: 'Button URL is required',
              })}
            />
            {buttonUrlError && (
              <span className="text-sm text-red-500 dark:text-red-400 mt-2">
                {buttonUrlError}
              </span>
            )}

            <InputField
              id="buttonText"
              extra=""
              variant="auth"
              state={buttonTextError ? 'error' : ''}
              placeholder="Enter button text"
              {...register('task.buttonText', {
                required: 'Button text is required',
                validate: value =>
                  value.length <= 30 ||
                  'Button text must be 30 characters or less',
              })}
            />
            {buttonTextError && (
              <span className="text-sm text-red-500 dark:text-red-400 mt-2">
                {buttonTextError}
              </span>
            )}
          </div>
        )}
      </div>
    </fieldset>
  );
};
