import { ButtonSM } from 'components/button/Small';
import {
  EAnaliticDataTypeEnum,
  useAnaliticsStore,
} from 'views/store/useAnaliticsStore';
import { GiveawayTask } from 'views/types/tasks';

interface IProps {
  tasks: GiveawayTask[];
  giveawayId: string;
}

export const GiveawayTasks: React.FC<IProps> = ({ tasks, giveawayId }) => {
  const { setAnalitic, setIsGiveawayTask, setSelectedGiveawayId } =
    useAnaliticsStore();

  const handleAnalyticsClick = (task: GiveawayTask) => {
    setAnalitic(EAnaliticDataTypeEnum.task, task.id, task.title);
    setIsGiveawayTask(true);
    setSelectedGiveawayId(giveawayId);
  };

  return (
    <article className="flex flex-col gap-4 w-full border-t border-gray-200 dark:!border-white/10">
      <h4 className="mt-8 font-semibold mb-4 text-lg">Tasks:</h4>
      <div>
        <div className="flex justify-between items-center mb-2">
          <p className="w-1/2 text-sm font-bold text-gray-600 dark:text-white">
            Name
          </p>
          <p className="w-1/2 text-sm font-bold text-gray-600 dark:text-white">
            Actions
          </p>
        </div>
        <div className="flex flex-col gap-2">
          {tasks.map((task, index) => (
            <div className="flex justify-between items-center" key={index}>
              <div className="w-1/2">{task.title}</div>
              <div className="w-1/2">
                <ButtonSM
                  variant="primary"
                  onClick={() => handleAnalyticsClick(task)}
                >
                  Analytics
                </ButtonSM>
              </div>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
};
