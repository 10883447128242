import { createStandaloneToast } from '@chakra-ui/toast';
import { $api } from './interceptor';

export const { toast } = createStandaloneToast();

export enum EPushMessageStatus {
  inProgress = 'IN PROGRESS',
  done = 'DONE',
}

export type PushMessage = {
  id: string;
  message: string;
  images: string[];
  buttonText: string;
  buttonUrl: string;
  targetedUsers: number;
  usersSuccess: number;
  usersFatal: number;
  isSendingProcessFinished: true;
  createdAt: string;
};

type TMetaInfo = {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
};

const pushMessagesApi = () => {
  const getPaginatedPushMessages = async ({
    page = 0,
    pageSize = 10,
  }: {
    page?: number;
    pageSize?: number;
  }) => {
    const { data } = await $api.get<{ data: PushMessage[]; meta: TMetaInfo }>(
      '/api/notifications/list',
      {
        params: {
          page: page + 1,
          limit: pageSize,
        },
      }
    );

    return data;
  };

  return {
    getPaginatedPushMessages,
  };
};

export const pushMessagesService = pushMessagesApi();
