import {
  useFormContext,
  useWatch,
  Controller,
  useFieldArray,
  ControllerRenderProps,
} from 'react-hook-form';
import InputField from 'components/fields/InputField';
import TextArea from 'components/fields/TextArea';
import Select from 'components/fields/Select';
import Switch from 'components/switch';
import { TaskFormScheme } from '../../scheme/taksForm';
import {
  InfluencerTask,
  TaskCategoriesEnum,
  TaskType,
} from 'views/types/tasks';
import getCountries from 'views/services/getCountries';
import getLanguages from 'views/services/getLanguages';
import { preventScrollOnNumberInput } from 'utils/input';
import { useState, useEffect } from 'react';
import { Button } from 'components/button';
import { MdAdd, MdClose } from 'react-icons/md';
import { giveawayService, toast } from 'views/services/giveawayService';
import { validateUrl } from 'utils/validateUrl';
import { tasksService } from 'views/services/tasksService';
import { RiDeleteBin6Line } from 'react-icons/ri';

type TaskFieldNames = keyof TaskFormScheme['task'];
type TaskFormFieldName =
  | `task.${TaskFieldNames}`
  | `telegramTask.${Extract<keyof TaskFormScheme['telegramTask'], string>}`
  | `referralTask.${Extract<keyof TaskFormScheme['referralTask'], string>}`;

interface TaskFormProps {
  isEdit: boolean;
  isGiveaway?: boolean;
  isLoadingImage?: boolean;
  setIsLoadingImage?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TaskForm = ({
  isEdit,
  isGiveaway = false,
  isLoadingImage,
  setIsLoadingImage,
}: TaskFormProps) => {
  const form = useFormContext<TaskFormScheme>();
  const influencers = form.getValues('influencerTasks')?.length
    ? form.getValues('influencerTasks')
    : undefined;

  const isUsersWithWalletOnly = useWatch({
    control: form.control,
    name: 'task.isUsersWithWalletOnly',
  });

  const isUsersWithoutWalletOnly = useWatch({
    control: form.control,
    name: 'task.isUsersWithoutWalletOnly',
  });

  const [countries, setCountries] = useState<
    { id: string; code: string; name: string }[]
  >([]);
  const [languages, setLanguages] = useState<{ code: string }[]>([]);

  const getIsAllCountriesSelected = () => {
    if (!countries.length) return false;

    return countries.every(
      country => form.getValues('task.targetCountries')[country.id]
    );
  };
  const getIsAllLanguagesSelected = () => {
    if (!languages.length) return false;

    return languages.every(
      language => form.getValues('task.targetLanguages')[language.code]
    );
  };
  const [isAllcountriesSelected, setIsAllcountriesSelected] = useState(
    getIsAllCountriesSelected()
  );
  const [isAllLanguagesSelected, setIsAllLanguagesSelected] = useState(
    getIsAllLanguagesSelected()
  );

  const [isLoading, setIsLoading] = useState(true);
  const [imagesURL, setImagesURL] = useState([{ order: 0, imageUrl: '' }]);
  const [isIconLoading, setIsIconLoading] = useState(false);
  const [updatedInfluencers, setUpdatedInfluencers] = useState<
    InfluencerTask[] | undefined
  >(influencers);
  const lastUpdatedInfluencer = updatedInfluencers?.length
    ? updatedInfluencers[updatedInfluencers.length - 1]
    : undefined;
  const { getInfluencer } = tasksService;
  const isInfluencersBlockDisabled =
    lastUpdatedInfluencer &&
    (!lastUpdatedInfluencer?.influencerId ||
      !validateUrl(lastUpdatedInfluencer?.redirectUrl));
  const isNotAllInfluencersSubmitted =
    influencers && influencers?.some(influencer => !influencer?.influencerId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fetchedCountries = await getCountries();
        const fetchedLanguages = await getLanguages();
        setCountries(fetchedCountries);
        setLanguages(fetchedLanguages);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();

    const stories = form
      .getValues('stories')
      ?.sort((a, b) => a.order - b.order);

    if (stories) {
      setImagesURL(stories);
      storyReplace(stories);
      form.setValue('storyTask.story', stories);
    }
    if (influencers?.length) {
      influencerReplace(influencers);
      form.setValue('influencerTasks', influencers);
      setUpdatedInfluencers(influencers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = async (image: File, order: number) => {
    if (!image) return;

    try {
      if (setIsLoadingImage) setIsLoadingImage(true);
      const formData = new FormData();
      formData.append('files', image, image.name);
      const uploadedImageUrl = await giveawayService.uploadAdminURL(formData);
      const filteredURL = imagesURL.filter(url => url.order !== order);
      const newUrls = [
        ...filteredURL,
        { imageUrl: uploadedImageUrl, order },
      ].sort((a, b) => a.order - b.order);

      if (uploadedImageUrl) {
        setImagesURL(newUrls);
      }
      form.setValue('storyTask.story', newUrls);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      if (setIsLoadingImage) setIsLoadingImage(false);
    }
  };

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    order: number
  ) => {
    const file = e.target.files ? e.target.files[0] : null;
    uploadImage(file, order);
  };

  if (!form) {
    throw new Error('useFormContext must be used inside a FormProvider');
  }

  const taskType = useWatch({
    control: form.control,
    name: 'task.type',
  });

  const isQuiz = taskType === TaskType.QUIZ;
  const isStory = taskType === TaskType.STORY;
  const isTelegram = taskType === TaskType.TELEGRAM;
  const isCommon = taskType === TaskType.COMMON;
  const isTransaction = taskType === TaskType.TRANSACTION;
  const isYoutube = taskType === TaskType.YOUTUBE;
  const isWallet = taskType === TaskType.WALLET;
  const isTicketsTask = taskType === TaskType.BUYTICKETS;
  const isOwnTicketsTask = taskType === TaskType.OWNTICKETS;
  const isTraffic = taskType === TaskType.TRAFFIC;
  const isTelegramStory = taskType === TaskType.TELEGRAM_STORY;

  const isForTGPremium = form.watch('task.isTgPremiumOnly');
  const isNotForTGPremium = form.watch('task.isTgStandardOnly');

  const { fields: questionFields, append: appendQuestion } = useFieldArray({
    control: form.control,
    name: 'quiz.questions',
  });

  const {
    fields: storyFields,
    append: storyAppend,
    replace: storyReplace,
  } = useFieldArray({
    control: form.control,
    name: 'storyTask.story',
  });

  const {
    fields: influencerFields,
    append: influencerAppend,
    replace: influencerReplace,
  } = useFieldArray({
    control: form.control,
    name: 'influencerTasks',
  });

  const handleChangeInfluencerUsername = async (
    value: string,
    fieldIndex: number
  ) => {
    const influencerData = await getInfluencer(value);
    let currentInfluencers =
      updatedInfluencers || form.getValues('influencerTasks');

    if (influencerData?.data) {
      const {
        id,
        referralCount,
        username: influencerUsername,
      } = influencerData.data;

      const isValueExists = currentInfluencers.find(
        influencer => influencer.order == fieldIndex
      );

      if (isValueExists) {
        currentInfluencers = currentInfluencers.map(influencer => {
          if (influencer?.order === fieldIndex) {
            return {
              influencerId: id,
              influencerUsername,
              referralCount,
              redirectUrl: influencer?.redirectUrl,
              order: fieldIndex,
            };
          } else {
            return influencer;
          }
        });
      } else {
        currentInfluencers.push({
          influencerId: id,
          influencerUsername,
          referralCount,
          redirectUrl: '',
          order: fieldIndex,
        });
      }

      setUpdatedInfluencers(currentInfluencers);
    }
  };

  const handleChangeInfluencerUrl = (value: string, fieldIndex: number) => {
    let currentInfluencers =
      updatedInfluencers || form.getValues('influencerTasks');
    const isValueExists = currentInfluencers.find(
      influencer => influencer.order == fieldIndex
    );
    if (isValueExists) {
      currentInfluencers = currentInfluencers.map(influencer => {
        if (influencer?.order === fieldIndex) {
          return {
            ...influencer,
            redirectUrl: value,
          };
        } else return influencer;
      });
    } else {
      currentInfluencers.push({
        influencerId: '',
        redirectUrl: value,
        order: fieldIndex,
      });
    }
    setUpdatedInfluencers(currentInfluencers);
  };

  const removeInfluencer = (fieldOrder: number) => {
    let currentInfluencers = form.getValues('influencerTasks') || [];
    currentInfluencers = currentInfluencers
      .filter(influencer => influencer?.order !== fieldOrder)
      .map((influencer, i) => ({ ...influencer, order: i }));
    influencerReplace(currentInfluencers);
    setUpdatedInfluencers(currentInfluencers);
  };

  const handleCorrectAnswerChange = (
    questionIndex: number,
    answerIndex: number
  ) => {
    const updatedQuestions = form
      .getValues('quiz.questions')
      .map((question, qIndex) => {
        if (qIndex === questionIndex) {
          // Set the selected answer as correct and others as false
          question.answers = question.answers.map((answer, aIndex) => ({
            ...answer,
            correct: aIndex === answerIndex,
          }));
        }
        return question;
      });

    form.setValue('quiz.questions', updatedQuestions, { shouldDirty: true });
  };

  const handleAddAnswer = async (questionIndex: number) => {
    const currentAnswers = form.getValues(
      `quiz.questions.${questionIndex}.answers`
    );

    if (currentAnswers.length >= 4) {
      toast({
        title: 'Quiz can contain maximum 4 answers',
        status: 'warning',
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    form.setValue(`quiz.questions.${questionIndex}.answers`, [
      ...currentAnswers,
      { text: '', correct: false },
    ]);
    const questions = form.getValues('quiz.questions');

    form.setValue('quiz.questions', questions);
  };

  const handleDeleteAnswer = (questionIndex: number, answerIndex: number) => {
    const currentAnswers = form.getValues(
      `quiz.questions.${questionIndex}.answers`
    );

    if (currentAnswers.length <= 2) {
      toast({
        title: 'Question must contain at least 2 answers',
        status: 'warning',
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    const filtered = currentAnswers.filter((_, i) => i !== answerIndex);

    form.setValue(`quiz.questions.${questionIndex}.answers`, filtered, {
      shouldDirty: true,
    });
    const questions = form.getValues('quiz.questions');

    form.setValue('quiz.questions', questions, { shouldDirty: true });
  };

  const handleDeleteQuestion = (questionIndex: number) => {
    const currentQuestions = form.getValues(`quiz.questions`);

    if (currentQuestions.length === 1) {
      toast({
        title: 'Quiz must contain at least 1 question',
        status: 'warning',
        isClosable: true,
        position: 'top-right',
      });
      return;
    }

    const filtered = currentQuestions.filter((_, i) => i !== questionIndex);

    form.setValue(`quiz.questions`, filtered, {
      shouldDirty: true,
    });
  };

  const addQuestion = () => {
    appendQuestion({
      question: '',
      answers: [
        { text: '', correct: false },
        { text: '', correct: false },
      ],
      description: '',
    });
  };

  const handleImageUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    const fieldName =
      e.target.id === 'storyImageUrl' ? 'task.storyImageUrl' : 'task.iconUrl';

    if (!file) return;

    if (file.size > 3000000) {
      e.target.value = '';
      alert('File is too large, max size is 3mb');
      return;
    }

    if (
      !file.type.includes('image/jpeg') &&
      !file.type.includes('image/png') &&
      !file.type.includes('image/svg+xml')
    ) {
      e.target.value = '';
      alert('Invalid file type, only jpg, jpeg, png, svg are allowed');
      return;
    }

    const formData = new FormData();
    formData.append('files', file, file.name);
    setIsIconLoading(true);
    giveawayService
      .uploadAdminURL(formData)
      .then(url => {
        form.setValue(fieldName, url, { shouldDirty: true });
        form.clearErrors(fieldName);
      })
      .catch(error => console.error('Error uploading icon:', error))
      .finally(() => setIsIconLoading(false));
  };

  const handleAllCountriesChange = () => {
    const isSelected = getIsAllCountriesSelected();

    const allSelectedCountries = countries.reduce(
      (acc, country) => ({
        ...acc,
        [country.id]: !isSelected,
      }),
      {}
    );

    form.setValue('task.targetCountries', allSelectedCountries, {
      shouldDirty: true,
    });
    setIsAllcountriesSelected(!isSelected);
  };

  const handleAllLanguagesChange = () => {
    const isSelected = getIsAllLanguagesSelected();

    const allSelectedLanguages = languages.reduce(
      (acc, language) => ({
        ...acc,
        [language.code]: !isSelected,
      }),
      {}
    );

    form.setValue('task.targetLanguages', allSelectedLanguages, {
      shouldDirty: true,
    });
    setIsAllLanguagesSelected(!isSelected);
  };

  const handleCountrySelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<TaskFormScheme, TaskFormFieldName>
  ) => {
    field.onChange(e.target.checked);
    setIsAllcountriesSelected(getIsAllCountriesSelected());
  };

  const handleLanguageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<TaskFormScheme, TaskFormFieldName>
  ) => {
    field.onChange(e.target.checked);
    setIsAllLanguagesSelected(getIsAllLanguagesSelected());
  };

  useEffect(() => {
    setIsAllcountriesSelected(getIsAllCountriesSelected());
    setIsAllLanguagesSelected(getIsAllLanguagesSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, languages]);

  return (
    <>
      {isEdit && (
        <>
          <fieldset className="mb-[15px] ml-1.5 flex">
            <label
              htmlFor="isActive"
              className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
            >
              Task enabled
            </label>
            <Switch id="isActive" {...form.register('task.isActive')} />
          </fieldset>
          {form.formState.errors.task?.isActive && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.task?.isActive.message}
            </span>
          )}
        </>
      )}

      <fieldset className="mb-[15px] flex flex-col">
        <Select
          variant="auth"
          readOnly={isEdit}
          disabled={isEdit}
          state={form.formState.errors.task?.type ? 'error' : undefined}
          extra="w-full mb-3"
          label="Task type"
          id="type"
          {...form.register('task.type', {
            required: 'This field is required',
          })}
        >
          <option value="" hidden>
            Select task type
          </option>
          {Object.values(TaskType).map(type => {
            if (type !== TaskType.QUIZ && type !== TaskType.STORY) {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            } else if (isGiveaway) {
              if (
                type !== TaskType.QUIZ &&
                type !== TaskType.STORY &&
                type !== TaskType.WALLET
              ) {
                return (
                  <option key={type} value={type}>
                    {type}
                  </option>
                );
              }
            } else {
              return null;
            }
          })}
        </Select>
      </fieldset>

      {!isGiveaway && (
        <fieldset className="mb-[15px] flex flex-col">
          <Select
            variant="auth"
            state={form.formState.errors.task?.category ? 'error' : undefined}
            extra="w-full mb-3"
            label="Task category"
            id="category"
            {...form.register('task.category', {
              required: 'This field is required',
            })}
          >
            <option value="" hidden>
              Select task category
            </option>
            {Object.values(TaskCategoriesEnum).map(category => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Select>
          {form.formState.errors.task?.category && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.task?.category.message}
            </span>
          )}
        </fieldset>
      )}

      <fieldset className="mb-[15px] flex flex-col">
        <div className="flex flex-col">
          <label>Task icon:</label>
          <input
            onChange={handleImageUrlChange}
            id="iconUrl"
            type="file"
            accept=".jpg,.jpeg,.png,.svg"
            className="w-fit"
          />
          {isIconLoading && <span>Loading...</span>}
          {form.getValues('task.iconUrl') && !isIconLoading && (
            <img
              src={form.getValues('task.iconUrl')}
              alt="Icon"
              className="my-2 w-12 h-12 object-cover rounded-lg"
            />
          )}
        </div>
      </fieldset>

      {isTelegramStory && (
        <fieldset className="mb-[15px] flex flex-col">
          <div className="flex flex-col">
            <label>Story image:</label>
            <input
              onChange={handleImageUrlChange}
              id="storyImageUrl"
              type="file"
              accept=".jpg,.jpeg,.png,.svg"
              className="w-fit"
            />
            {isIconLoading && <span>Loading...</span>}
            {form.getValues('task.storyImageUrl') && !isIconLoading && (
              <img
                src={form.getValues('task.storyImageUrl')}
                alt="Icon"
                className="my-2 w-12 h-12 object-cover rounded-lg"
              />
            )}
          </div>
        </fieldset>
      )}

      {!isStory ? (
        <>
          <fieldset className="mb-[15px] flex flex-col">
            <InputField
              variant="auth"
              state={form.formState.errors.task?.title ? 'error' : undefined}
              extra="w-full mb-3"
              label="Title"
              placeholder="Enter task title"
              id="title"
              type="string"
              {...form.register('task.title', {
                required: 'This field is required',
              })}
            />
            {form.formState.errors.task?.title && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.task?.title.message}
              </span>
            )}
          </fieldset>

          {isTraffic && (
            <>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.task?.redirectUrl
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Redirect URL"
                  placeholder="https://t.me/target_bot/BlockBitsApp?startapp=tappads_61_42"
                  id="redirectUrl"
                  type="url"
                  {...form.register('task.redirectUrl', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.task?.redirectUrl && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.task?.redirectUrl.message}
                  </span>
                )}
              </fieldset>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.trafficTask?.partnerUrl
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Partner API url"
                  placeholder="https://api.your-partner.com/check-registerd?telega_id={tgid}"
                  id="partnerUrl"
                  type="url"
                  {...form.register('trafficTask.partnerUrl', {
                    required: 'This field is required',
                    validate: url =>
                      url.includes('{tgid}') ||
                      "Url must contain '{tgid}' parameter",
                  })}
                />
                {form.formState.errors?.trafficTask && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.trafficTask?.partnerUrl.message}
                  </span>
                )}
              </fieldset>
            </>
          )}

          {taskType && !isTelegram && !isCommon && !isWallet && !isTraffic && (
            <div className="flex flex-col">
              <fieldset className="mb-[15px] flex flex-col">
                <TextArea
                  variant="auth"
                  state={
                    form.formState.errors.task?.description
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Description"
                  placeholder="Enter task description"
                  id="description"
                  {...form.register('task.description', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.task?.description && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.task?.description.message}
                  </span>
                )}
              </fieldset>
            </div>
          )}

          {!isTicketsTask && (
            <fieldset className="mb-[15px] flex flex-col">
              <InputField
                variant="auth"
                state={
                  form.formState.errors.task?.rewardPointsAmount
                    ? 'error'
                    : undefined
                }
                extra="w-full mb-3"
                label="Reward (points)"
                placeholder="Enter reward points"
                id="rewardPointsAmount"
                type="number"
                onWheel={preventScrollOnNumberInput}
                {...form.register('task.rewardPointsAmount', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
                    message: 'Invalid number format',
                  },
                })}
              />
              {form.formState.errors.task?.rewardPointsAmount && (
                <span className="text-sm text-red-500 dark:text-red-400">
                  {form.formState.errors.task?.rewardPointsAmount.message}
                </span>
              )}
            </fieldset>
          )}

          <fieldset className="mb-[15px] flex flex-col">
            <InputField
              variant="auth"
              state={
                form.formState.errors?.task?.usersCompletedCountMax
                  ? 'error'
                  : undefined
              }
              extra="w-full mb-3"
              label="Maximum users limit"
              placeholder="Enter the maximum number of users who can complete the task"
              id="usersCompletedCountMax"
              type="number"
              onWheel={preventScrollOnNumberInput}
              {...form.register('task.usersCompletedCountMax', {
                pattern: {
                  value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
                  message: 'Invalid number format',
                },
              })}
            />
            {form.formState.errors?.task?.usersCompletedCountMax && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors?.task?.usersCompletedCountMax.message}
              </span>
            )}
          </fieldset>

          {isTicketsTask && (
            <>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.buyTicketTask?.starsPrice
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="TG Stars Amount"
                  placeholder="Enter TG Stars Amount"
                  id="starsPrice"
                  type="number"
                  step={1}
                  onWheel={preventScrollOnNumberInput}
                  {...form.register('buyTicketTask.starsPrice', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[0-9]+$/g,
                      message: 'Invalid number format',
                    },
                    validate: value =>
                      value > 0 || 'Value must be greater than 0',
                  })}
                />
                {form.formState.errors.buyTicketTask?.starsPrice && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.buyTicketTask.starsPrice.message}
                  </span>
                )}
              </fieldset>

              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.buyTicketTask?.ticketsAmount
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Tickets Amount"
                  placeholder="Enter tickets amount to receive"
                  id="ticketsAmount"
                  type="number"
                  step={1}
                  onWheel={preventScrollOnNumberInput}
                  {...form.register('buyTicketTask.ticketsAmount', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[0-9]+$/g,
                      message: 'Invalid number format',
                    },
                    validate: value =>
                      value > 0 || 'Value must be greater than 0',
                  })}
                />
                {form.formState.errors.buyTicketTask?.ticketsAmount && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.buyTicketTask.ticketsAmount.message}
                  </span>
                )}
              </fieldset>
            </>
          )}

          {isTransaction && (
            <>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.task?.redirectUrl
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Transaction Wallet Address"
                  placeholder="0QBVjTxNDj...GepUKg8wlj"
                  id="transactionWalletAddress"
                  type="text"
                  {...form.register('transactionWalletAddress', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.task?.redirectUrl && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.task?.redirectUrl.message}
                  </span>
                )}
              </fieldset>
              <fieldset className="mb-[15px] flex flex-col grow">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.youtubeTask?.secretWord
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Transaction Amount"
                  placeholder="Enter the amount higher than 0.1 TON"
                  id="transactionAmount"
                  type="number"
                  step="any"
                  onWheel={preventScrollOnNumberInput}
                  {...form.register('transactionAmount', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.youtubeTask?.secretWord && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.youtubeTask?.secretWord.message}
                  </span>
                )}
              </fieldset>
              <fieldset className="mb-[15px] flex flex-col grow">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.transactionTask?.starsPrice
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Stars Amount"
                  placeholder="Enter the amount of stars"
                  id="starsPriceTransaction"
                  type="number"
                  step="any"
                  onWheel={preventScrollOnNumberInput}
                  {...form.register('transactionTask.starsPrice', {
                    required: false,
                  })}
                />
                {form.formState.errors.transactionTask?.starsPrice && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.transactionTask?.starsPrice.message}
                  </span>
                )}
              </fieldset>
            </>
          )}

          {isOwnTicketsTask && (
            <fieldset className="mb-[15px] flex flex-col">
              <InputField
                variant="auth"
                state={
                  form.formState.errors.buyTicketTask?.ticketsAmount
                    ? 'error'
                    : undefined
                }
                extra="w-full mb-3"
                label="Tickets Amount"
                placeholder="Enter tickets amount that user must have"
                id="ticketsAmount"
                type="number"
                step={1}
                onWheel={preventScrollOnNumberInput}
                {...form.register('ownTicketTask.ticketsAmount', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[0-9]+$/g,
                    message: 'Invalid number format',
                  },
                  validate: value =>
                    +value > 0 || 'Value must be greater than 0',
                })}
              />
              {form.formState.errors.buyTicketTask?.ticketsAmount && (
                <span className="text-sm text-red-500 dark:text-red-400">
                  {form.formState.errors.buyTicketTask.ticketsAmount.message}
                </span>
              )}
            </fieldset>
          )}

          {taskType === TaskType.REFERRAL && (
            <fieldset className="mb-[15px] flex flex-col">
              <InputField
                variant="auth"
                state={
                  form.formState.errors?.referralTask?.inviteCount
                    ? 'error'
                    : undefined
                }
                extra="w-full mb-3"
                label="Invite count"
                placeholder="Enter invite count"
                id="inviteCount"
                type="number"
                onWheel={preventScrollOnNumberInput}
                {...form.register('referralTask.inviteCount', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
                    message: 'Invalid number format',
                  },
                })}
              />
              {form.formState.errors?.referralTask?.inviteCount && (
                <span className="text-sm text-red-500 dark:text-red-400">
                  {form.formState.errors?.referralTask?.inviteCount.message}
                </span>
              )}
            </fieldset>
          )}

          <div className="flex gap-8 ">
            {!isGiveaway && (
              <fieldset className="mb-[15px] flex flex-col flex-1">
                <div className="flex items-center mb-2 flex-wrap">
                  <label className="text-sm font-medium text-navy-700 dark:text-white">
                    Target Countries
                  </label>

                  <label
                    id="select-all_countries"
                    className="ml-4 text-sm flex items-center justify-center"
                  >
                    <input
                      type="checkbox"
                      className="mr-1 h-3 w-3"
                      onChange={handleAllCountriesChange}
                      checked={isAllcountriesSelected}
                    />
                    Select All
                  </label>
                </div>
                <div className="space-y-2 max-h-[300px] overflow-y-auto">
                  {isLoading && <span>Loading...</span>}
                  {!isLoading &&
                    countries.map(country => (
                      <label key={country.id} className="flex items-center">
                        <Controller
                          name={
                            `task.targetCountries.${country.id}` as TaskFormFieldName
                          }
                          control={form.control}
                          defaultValue={false}
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              id={`country-${country.id}`}
                              checked={!!field.value}
                              onChange={e => handleCountrySelect(e, field)}
                              onBlur={field.onBlur}
                            />
                          )}
                        />
                        <span className="ml-2">{country.name}</span>
                      </label>
                    ))}
                </div>
              </fieldset>
            )}

            {!isGiveaway && (
              <fieldset className="mb-[15px] flex flex-col flex-1">
                <div className="flex items-center mb-2 flex-wrap">
                  <label className="text-sm font-medium text-navy-700 dark:text-white">
                    Target Languages
                  </label>

                  <label
                    id="select-all_languages"
                    className="ml-4 text-sm flex items-center justify-center"
                  >
                    <input
                      type="checkbox"
                      className="mr-1 h-3 w-3"
                      onChange={handleAllLanguagesChange}
                      checked={isAllLanguagesSelected}
                    />
                    Select All
                  </label>
                </div>
                <div className="space-y-2 max-h-[300px] overflow-y-auto">
                  {isLoading && <span>Loading...</span>}
                  {!isLoading &&
                    languages.map(language => (
                      <label key={language.code} className="flex items-center">
                        <Controller
                          name={
                            `task.targetLanguages.${language.code}` as TaskFormFieldName
                          }
                          control={form.control}
                          defaultValue={false}
                          render={({ field }) => (
                            <input
                              type="checkbox"
                              id={`language-${language.code}`}
                              checked={!!field.value}
                              onChange={e => handleLanguageChange(e, field)}
                              onBlur={field.onBlur}
                            />
                          )}
                        />
                        <span className="ml-2">{language.code}</span>
                      </label>
                    ))}
                </div>
              </fieldset>
            )}
          </div>

          {isQuiz && (
            <>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.quiz?.approximateTime
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Approximate time"
                  placeholder="Enter approximate time"
                  id="time"
                  type="number"
                  onWheel={preventScrollOnNumberInput}
                  {...form.register('quiz.approximateTime', {
                    required: 'This field is required',
                    pattern: {
                      value: /^(?!0$)[0-9]+([.,]{1}[0-9]+)?$/g,
                      message: 'Invalid number format',
                    },
                  })}
                />
                {form.formState.errors.quiz?.approximateTime && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.quiz?.approximateTime.message}
                  </span>
                )}
              </fieldset>
              <>
                {questionFields.map((questionField, questionIndex) => {
                  return (
                    <fieldset
                      key={questionField.id}
                      className="mb-[15px] flex flex-col grow"
                      disabled={isLoading}
                    >
                      <InputField
                        disabled={isLoading}
                        extra="w-full mb-3"
                        id="quiz.question"
                        variant="auth"
                        label={`Question ${questionIndex + 1}`}
                        placeholder="Enter your question"
                        {...form.register(
                          `quiz.questions.${questionIndex}.question`,
                          {
                            required: 'Question is required',
                          }
                        )}
                      />

                      {questionField.answers.map((answer, answerIndex) => (
                        <div
                          key={answerIndex}
                          className="flex flex-row items-center gap-2"
                        >
                          <input
                            disabled={isLoading}
                            type="text"
                            className="text-black bg-transparent dark:bg-gray-800 dark:text-white"
                            color="black"
                            placeholder={`Answer ${answerIndex + 1}`}
                            {...form.register(
                              `quiz.questions.${questionIndex}.answers.${answerIndex}.text`,
                              {
                                required: 'Answer is required',
                              }
                            )}
                          />
                          <label className="flex flex-row items-center gap-1">
                            <input
                              disabled={isLoading}
                              type="radio"
                              name={`correct-answer-${questionIndex}`}
                              value={answerIndex}
                              checked={answer.correct}
                              onChange={() =>
                                handleCorrectAnswerChange(
                                  questionIndex,
                                  answerIndex
                                )
                              }
                            />
                            Correct
                          </label>

                          <button
                            disabled={isLoading}
                            type="button"
                            className="text-violet11 hover:bg-violet4 focus:shadow-violet7 right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                            aria-label="Close"
                            onClick={() =>
                              handleDeleteAnswer(questionIndex, answerIndex)
                            }
                          >
                            <MdClose />
                          </button>
                        </div>
                      ))}

                      <div className="flex flex-row justify-center gap-3 mt-2">
                        <Button
                          onClick={() => handleAddAnswer(questionIndex)}
                          className="flex self-center"
                        >
                          <MdAdd />
                          Add Answer
                        </Button>
                        <Button
                          onClick={() => handleDeleteQuestion(questionIndex)}
                          className="flex self-center"
                        >
                          <MdClose />
                          Delete Question
                        </Button>
                      </div>
                    </fieldset>
                  );
                })}
              </>

              <Button onClick={addQuestion}>
                <MdAdd />
                Add Question
              </Button>
            </>
          )}

          {!isGiveaway && (
            <fieldset className="mb-[15px] flex flex-col">
              <label className="mb-2 text-sm font-medium text-navy-700 dark:text-white">
                Options
              </label>

              <div className="flex items-center gap-2">
                <label className="flex items-center">
                  <Controller
                    name="task.isTgPremiumOnly"
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id="isTgPremiumOnly"
                        className={`${isNotForTGPremium ? '!cursor-not-allowed' : ''}`}
                        disabled={!!isNotForTGPremium}
                        checked={!!field.value}
                        onChange={e => field.onChange(e.target.checked)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span
                    className={`ml-2 ${isNotForTGPremium ? 'text-gray-500' : ''}`}
                  >
                    Telegram Premium only
                  </span>
                </label>
              </div>

              <div className="flex items-center gap-2">
                <label className="flex items-center">
                  <Controller
                    name="task.isTgStandardOnly"
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id="isTgStandardOnly"
                        className={`${isForTGPremium ? '!cursor-not-allowed' : ''}`}
                        disabled={isForTGPremium}
                        checked={!!field.value}
                        onChange={e => field.onChange(e.target.checked)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span
                    className={`ml-2 ${isForTGPremium ? 'text-gray-500' : ''}`}
                  >
                    Not Telegram Premium only
                  </span>
                </label>
              </div>

              <div className="flex items-center gap-2">
                <label className="flex items-center">
                  <Controller
                    name="task.isUsersWithWalletOnly"
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id="isUsersWithWalletOnly"
                        disabled={isUsersWithoutWalletOnly}
                        className={`${isUsersWithoutWalletOnly ? '!cursor-not-allowed' : ''}`}
                        checked={!!field.value}
                        onChange={e => field.onChange(e.target.checked)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span
                    className={`ml-2 ${isUsersWithoutWalletOnly ? 'text-gray-500' : ''}`}
                  >
                    Users with wallet only
                  </span>
                </label>
              </div>

              <div className="flex items-center gap-2">
                <label className="flex items-center">
                  <Controller
                    name="task.isUsersWithoutWalletOnly"
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id="isUsersWithoutWalletOnly"
                        disabled={isUsersWithWalletOnly}
                        className={`${isUsersWithWalletOnly ? '!cursor-not-allowed' : ''}`}
                        checked={!!field.value}
                        onChange={e => field.onChange(e.target.checked)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span
                    className={`ml-2 ${isUsersWithWalletOnly ? 'text-gray-500' : ''}`}
                  >
                    Users without wallet only
                  </span>
                </label>
              </div>
              <div className="flex items-center gap-2">
                <label className="flex items-center">
                  <Controller
                    name="task.onlyForIos"
                    control={form.control}
                    defaultValue={false}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        id="onlyForIos"
                        checked={!!field.value}
                        onChange={e => field.onChange(e.target.checked)}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                  <span className="ml-2">Only for IOS users</span>
                </label>
              </div>
            </fieldset>
          )}

          {taskType === TaskType.TELEGRAM && (
            <div className="flex flex-col md:flex-row gap-4">
              <fieldset className="mb-[15px] flex flex-col grow">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.telegramTask?.resourceId
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Telegram Resource ID"
                  placeholder="Enter Telegram Resource ID"
                  disabled={isLoading}
                  id="resourceId"
                  type="number"
                  onWheel={preventScrollOnNumberInput}
                  {...form.register('telegramTask.resourceId', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.telegramTask?.resourceId && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.telegramTask?.resourceId.message}
                  </span>
                )}
              </fieldset>
              <fieldset className="mb-[15px] flex flex-col grow">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.task?.redirectUrl
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Redirect URL"
                  placeholder="https://t.me/ or other URL"
                  id="redirectUrl"
                  type="url"
                  {...form.register('task.redirectUrl', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.task?.redirectUrl && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.task?.redirectUrl.message}
                  </span>
                )}
              </fieldset>
            </div>
          )}
          {taskType === TaskType.SNAPSHOT && (
            <>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.task?.redirectUrl
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Redirect URL"
                  placeholder="https://example.com/"
                  id="redirectUrl"
                  type="url"
                  {...form.register('task.redirectUrl', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.task?.redirectUrl && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.task?.redirectUrl.message}
                  </span>
                )}
              </fieldset>
            </>
          )}
          {isYoutube && (
            <>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.task?.redirectUrl
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Video URL"
                  placeholder="https://www.youtube.com/watch?v=SVIMiorXxac"
                  id="redirectUrl"
                  type="url"
                  {...form.register('task.redirectUrl', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.task?.redirectUrl && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.task?.redirectUrl.message}
                  </span>
                )}
              </fieldset>
              <fieldset className="mb-[15px] flex flex-col grow">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.youtubeTask?.secretWord
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Secret word"
                  placeholder="Enter secret word"
                  id="secretWord"
                  type="text"
                  onWheel={preventScrollOnNumberInput}
                  {...form.register('youtubeTask.secretWord', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.youtubeTask?.secretWord && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.youtubeTask?.secretWord.message}
                  </span>
                )}
              </fieldset>
            </>
          )}

          {isCommon && (
            <>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  variant="auth"
                  state={
                    form.formState.errors.task?.redirectUrl
                      ? 'error'
                      : undefined
                  }
                  extra="w-full mb-3"
                  label="Redirect URL"
                  placeholder="https://example.com/"
                  id="redirectUrl"
                  type="url"
                  {...form.register('task.redirectUrl', {
                    required: 'This field is required',
                  })}
                />
                {form.formState.errors.task?.redirectUrl && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.task?.redirectUrl.message}
                  </span>
                )}
              </fieldset>
            </>
          )}
        </>
      ) : (
        <>
          <div className='className="flex flex-col gap-3 mb-3'>
            {storyFields.map((field, index) => (
              <div key={field.id} className="flex flex-col gap-1">
                <div>
                  <label>Image URL:</label>
                  <input
                    type="file"
                    onChange={e => handleImageChange(e, index)}
                    accept=".jpg,.jpeg,.png"
                    placeholder="Provide image URL"
                    className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none transition-opacity duration-300 ${
                      form.formState.errors.storyTask?.story
                        ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                        : 'border-gray-200 dark:!border-white/10 dark:text-white'
                    }`}
                  />
                  {imagesURL.length &&
                    !!imagesURL[field.order]?.imageUrl?.length && (
                      <div className="mt-4">
                        <img
                          src={imagesURL[field.order]?.imageUrl}
                          alt="Uploaded"
                          className="w-20 h-20 object-cover"
                        />
                      </div>
                    )}

                  {form.formState.errors.storyTask?.story?.[index]
                    ?.imageUrl && (
                    <span className="text-sm text-red-500 dark:text-red-400">
                      {form.formState.errors.storyTask?.story.message}
                    </span>
                  )}
                </div>
                <div>
                  {form.formState.errors.storyTask?.story?.[index]?.order && (
                    <p>
                      {
                        form.formState.errors.storyTask?.story[index]?.order
                          ?.message
                      }
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>

          <Button
            disabled={isLoadingImage}
            onClick={() =>
              storyAppend({ imageUrl: '', order: imagesURL.length })
            }
            className="disabled:opacity-75"
          >
            <MdAdd />
            {isLoadingImage ? 'Uploading image...' : 'Add Story'}
          </Button>
        </>
      )}
      {!isGiveaway &&
        !isYoutube &&
        !isTelegram &&
        !isStory &&
        !isTransaction && (
          <>
            <div className='className="flex flex-col gap-3 mb-3'>
              {influencerFields.map((field, index) => (
                <div key={field.id} className="flex flex-col gap-1">
                  <div>
                    <label className="font-semibold flex items-center gap-4">
                      Influencer{' '}
                      <RiDeleteBin6Line
                        color="red"
                        className="cursor-pointer"
                        onClick={() => removeInfluencer(index)}
                      />
                    </label>
                    <div
                      className={`grid grid-cols-${!influencers?.[index].influencerId ? '2 gap-4' : '1'}`}
                    >
                      {!influencers?.[index].influencerId && (
                        <InputField
                          extra="w-full mb-3"
                          id="redirectUrl"
                          variant="auth"
                          type="text"
                          disabled={!!influencers?.[index].influencerId}
                          onChange={e =>
                            handleChangeInfluencerUsername(
                              e.target.value,
                              index
                            )
                          }
                          placeholder="Enter the influencer telegram name"
                          className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none transition-opacity duration-300 border-gray-200 dark:!border-white/10 dark:text-white'
                    }`}
                        />
                      )}
                      <div className="h-full flex items-center font-semibold text-navy-700">
                        {updatedInfluencers?.find(
                          influencer => influencer?.order == index
                        )?.influencerId && (
                          <>
                            TG username:{' '}
                            <span className="mx-1 font-semibold text-blue-700">
                              {
                                updatedInfluencers?.find(
                                  influencer => influencer?.order == index
                                )?.influencerUsername
                              }
                            </span>
                            (referrals:{' '}
                            <span className="font-semibold ml-1 text-blue-700">
                              {
                                updatedInfluencers?.find(
                                  influencer => influencer?.order == index
                                )?.referralCount
                              }
                            </span>
                            )
                          </>
                        )}
                      </div>
                      <InputField
                        variant="auth"
                        defaultValue={
                          influencers?.find(
                            influencer => influencer?.order == index
                          )?.redirectUrl || ''
                        }
                        state={
                          form.formState.errors.task?.redirectUrl
                            ? 'error'
                            : undefined
                        }
                        disabled={!!influencers?.[index].influencerId}
                        onChange={e =>
                          handleChangeInfluencerUrl(e.target.value, index)
                        }
                        extra="w-full mb-3"
                        label="Influencer group redirect URL"
                        placeholder="https://t.me/ or other URL"
                        id="redirectUrl"
                        type="url"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Button
              disabled={isInfluencersBlockDisabled}
              onClick={() => {
                if (isNotAllInfluencersSubmitted) {
                  influencerReplace(updatedInfluencers);
                } else {
                  const dataToAppend = {
                    influencerId: '',
                    redirectUrl: '',
                    order: influencers?.length
                      ? Math.max(
                          ...influencers?.map(influencer => influencer?.order)
                        ) + 1
                      : 0,
                  };
                  influencerAppend(dataToAppend);
                  setUpdatedInfluencers([
                    ...(updatedInfluencers || []),
                    dataToAppend,
                  ]);
                }
              }}
              className={`${
                isInfluencersBlockDisabled && 'opacity-75'
              } active:opacity-75 duration-400 ease-in-out`}
              style={
                isNotAllInfluencersSubmitted
                  ? { backgroundColor: '#13a808' }
                  : {}
              }
            >
              {isNotAllInfluencersSubmitted ? (
                'Submit influencer'
              ) : (
                <>
                  {' '}
                  <MdAdd /> Add influencer
                </>
              )}
            </Button>
          </>
        )}
    </>
  );
};
