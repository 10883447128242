export const getTelegramIdFromReferralLink = (string: string): string => {
  try {
    const url = new URL(string);
    if (url.hostname === 't.me' && url.searchParams.has('startapp')) {
      return url.searchParams.get('startapp') || string;
    }
    return string;
  } catch {
    return string;
  }
};
