import { TSecretCombos } from 'views/types/analytics';
import { $api } from './interceptor';

export const comboApi = () => {
  const getSecretCombo = async () => {
    const { data } = await $api.get<TSecretCombos[][]>(
      '/api/secret-combo/sequence'
    );

    return data;
  };

  return {
    getSecretCombo,
  };
};

export const combosService = comboApi();
