export enum ESetReferralEvents {
  LAUNCH_APP = 'LAUNCH_APP',
  CLAIM_DAILY_REWARD = 'CLAIM_DAILY_REWARD',
}

export interface User {
  id: string;
  points: number;
  telegramUserId: string;
  username: string;
  updatedAt: string;
  reffererTelegramId: string;
  telegramName: string;
  pointsPerGuess: number;
  age: number;
  gender: string;
  completedTasks: number;
  completedGiveaways: number;
  premiumSubscriptionPlanName: string;
  customIncomePercent: number | undefined;
  setReferralEvent: ESetReferralEvents;
  selectedCountry: {
    name: string;
  };
  wallet: {
    id: string;
    address: string;
    memo: string;
    provider: string;
    createdAt: string;
  };
  country: {
    id: string;
    code: string;
    name: string;
  };
  userAgent: string;
  lastDevice: string;
  last_device?: string;
  ipAddress: string;
  lastActiveAt: string;
  isPremium: boolean;
  referralCount: number;
  totalTonSpent: number;
  totalTgStarsSpent: number;
  userLanguage: string;
  energy: number;
  maxEnergy: number;
  energyRestorationRate: number;
  lastGuessAt: string;
  amountOfPredictions: number;
  createdAt: string;
  utmSource?: string;
}

export interface UserStatistic {
  role: string;
  count: number;
}

export interface TableStore {
  setState: (newState: Partial<unknown>) => void;
  getState: () => unknown;
}

export type Platforms = {
  ios: number;
  android: number;
};

export type Countries = {
  [key: string]: number;
};

export type Languages = {
  [key: string]: number;
};

export type SelectedCountries = {
  [key: string]: number;
};

export type TAvgTgAge = string;

export type TAppUsersGenders = {
  male: number;
  female: number;
};

export type TAppStatisticsUtmSources = {
  [key: string]: number;
};

export type UsersStatistics = {
  avgTelegramUserAge: TAvgTgAge;
  activeYesterdayCount: number;
  totalCount: number;
  totalFilledBio: number;
  platforms: Platforms;
  countries: Countries;
  selectedCountries: SelectedCountries;
  languages: Languages;
  genders: TAppUsersGenders;
  tgPremiumCount: number;
  duckPremiumCount: number;
  utmSources: TAppStatisticsUtmSources;
  withWalletCount: number;
};
